import React from 'react';
import { mainGreen } from "../../constants";

const divideTopic = (topic) => {
    const parts = topic.split(' ');

    const beginning = parts[0];
    const greenPart = parts[1];
    let rest = " ";
    for (let i = 2; i < parts.length; i++) {
        rest += parts[i] + ' ';
    }

    return { beginning, greenPart, rest }
}

const Topic = ({ topic }) => {
    const [dividedTopic, setDividedTopic] = React.useState({
        beginning: '',
        greenPart: '',
        rest: ''
    });

    React.useEffect(() => {
        const { beginning, greenPart, rest } = divideTopic(topic);
        setDividedTopic({
            beginning,
            greenPart,
            rest
        })
    }, []);

    return (
        <div className='blog-topic-container'>
            <p className='blog-topic'>
                {`${dividedTopic.beginning} `}
                <span> {dividedTopic.greenPart} </span>
                {`${dividedTopic.rest}`}
            </p>
        </div>
    );
};

export default Topic;