import React from 'react';
import PrivacyCard from "./PrivacyCard";
import PrivacySection from "./Privacy-Section";
import {LanguageContext} from "../../contexts/LanguageContext";

const PrivacyBoard = () => {
    const _current = Number(localStorage.getItem('privacy-current'));
    const {content} = React.useContext(LanguageContext);
    const [current, setCurrent] = React.useState(_current || 1);

    const set_Current = (value) => {
        setCurrent(value);
        localStorage.setItem('privacy-current', String(value));
    }

    const sections = {
        1: <PrivacySection
            img={require('../../assets/images/privacy-policy/consent.png')}
            title={content.pages.privacy.consent.title}
        >
            {content.pages.privacy.consent.text}
        </PrivacySection>,
        2: <PrivacySection
            img={require('../../assets/images/privacy-policy/legal-disclaimer.png')}
            title={content.pages.privacy.disclaimer.title}
        >
            {content.pages.privacy.disclaimer.text}
        </PrivacySection>,
        3: <PrivacySection
            img={require('../../assets/images/privacy-policy/use-of-information.png')}
            title={content.pages.privacy.informationUse.title}
        >
            {content.pages.privacy.informationUse.text}
        </PrivacySection>,
        4: <PrivacySection
            img={require('../../assets/images/privacy-policy/security-of-information.png')}
            title={content.pages.privacy.informationSecurity.title}
        >
            {content.pages.privacy.informationSecurity.text}
        </PrivacySection>,
        5: <PrivacySection
            img={require('../../assets/images/privacy-policy/link-to-sites.png')}
            title={content.pages.privacy.links.title}
        >
            {content.pages.privacy.links.text}
        </PrivacySection>,
        6: <PrivacySection
            img={require('../../assets/images/privacy-policy/contact-of-information.png')}
            title={content.pages.privacy.contact.title}
        >
            {content.pages.privacy.contact.text}
        </PrivacySection>,
        7: <PrivacySection
            img={require('../../assets/images/privacy-policy/terms-of-use.png')}
            title={content.pages.privacy.terms.title}
        >
            {content.pages.privacy.terms.text}
        </PrivacySection>,
        8: <PrivacySection
            img={require('../../assets/images/privacy-policy/changes-to-policy.png')}
            title={content.pages.privacy.changes.title}
        >
            {content.pages.privacy.changes.text}
        </PrivacySection>,
        9: <PrivacySection
            img={require('../../assets/images/privacy-policy/personal-information-access.png')}
            title={content.pages.privacy.access.title}
        >
            {content.pages.privacy.access.text}
        </PrivacySection>,
    }

    return (
        <div className='privacy-board'>
            <PrivacyCard
                id={1}
                current={current}
                setCurrent={set_Current}
                img={require('../../assets/images/privacy-policy/consent.png')}
                text={content.pages.privacy.consent.title}
            />
            <PrivacyCard
                id={2}
                current={current}
                setCurrent={set_Current}
                img={require('../../assets/images/privacy-policy/legal-disclaimer.png')}
                text={content.pages.privacy.disclaimer.title}
            />
            <PrivacyCard
                id={3}
                current={current}
                setCurrent={set_Current}
                img={require('../../assets/images/privacy-policy/use-of-information.png')}
                text={content.pages.privacy.informationUse.title}
            />
            <PrivacyCard
                id={4}
                current={current}
                setCurrent={set_Current}
                img={require('../../assets/images/privacy-policy/security-of-information.png')}
                text={content.pages.privacy.informationSecurity.title}
            />
            <PrivacyCard
                id={5}
                current={current}
                setCurrent={set_Current}
                img={require('../../assets/images/privacy-policy/link-to-sites.png')}
                text={content.pages.privacy.links.title}
            />
            <PrivacyCard
                id={6}
                current={current}
                setCurrent={set_Current}
                img={require('../../assets/images/privacy-policy/contact-of-information.png')}
                text={content.pages.privacy.contact.title}
            />
            <PrivacyCard
                id={7}
                current={current}
                setCurrent={set_Current}
                img={require('../../assets/images/privacy-policy/terms-of-use.png')}
                text={content.pages.privacy.terms.title}
            />
            <PrivacyCard
                id={8}
                current={current}
                setCurrent={set_Current}
                img={require('../../assets/images/privacy-policy/changes-to-policy.png')}
                text={content.pages.privacy.changes.title}
            />
            <PrivacyCard
                id={9}
                current={current}
                setCurrent={set_Current}
                img={require('../../assets/images/privacy-policy/personal-information-access.png')}
                text={content.pages.privacy.access.title}
            />
            <div className='privacy-board-content'>
                <div className='privacy-board-content-wrapper'>
                    {sections[current]}
                </div>
            </div>
        </div>
    );
};

export default PrivacyBoard;