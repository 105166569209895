import React from 'react';
import styles from './SearchedItem.module.scss';
import { useNavigate } from "react-router";
import { mainGreen } from '../../constants';

const SearchedItem = ({ id, title }) => {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/blog/${id}`);
        window.location.reload();
    }

    return (
        <div onClick={onClick} className={styles.container}>
            <p> {title} </p>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0L0 1.41Z" fill="white" style={{
                    stroke: `${mainGreen}`
                }} />
            </svg>
        </div>
    );
};

export default SearchedItem;