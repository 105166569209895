import React from 'react';
import Reason from "../../../components/Reason/Reason";
import {LanguageContext} from "../../../contexts/LanguageContext";

const AboutReasons = () => {
    const {content} = React.useContext(LanguageContext);

    return (
        <div>
            <h1 className='about-title'
                style={{
                textAlign: 'center',
                marginTop: '2em',
                marginBottom: '1.5em'
            }}> {content.pages.about.why}? </h1>
            <div className='about-reasons'>
                <Reason
                    className='about-reason'
                    title={content.pages.about.reasons.team.title}
                    image={require('../../../assets/images/team.png')}
                    order={ 1 }
                >
                    {content.pages.about.reasons.team.text}
                </Reason>
                <Reason
                    className='about-reason'
                    title={content.pages.about.reasons.schedule.title}
                    image={require('../../../assets/images/calendar.png')}
                    order={ 2 }
                >
                    {content.pages.about.reasons.schedule.text}
                </Reason>
                <Reason
                    className='about-reason'
                    title={content.pages.about.reasons.security.title}
                    image={require('../../../assets/images/security.png')}
                    order={ 3 }
                >
                    {content.pages.about.reasons.security.text}
                </Reason>
                <Reason
                    className='about-reason'
                    title={content.pages.about.reasons.cost.title}
                    image={require('../../../assets/images/money.png')}
                    order={ 4 }
                >
                    {content.pages.about.reasons.cost.text}
                </Reason>
                <Reason
                    className='about-reason'
                    title={content.pages.about.reasons.dev.title}
                    image={require('../../../assets/images/laptop.png')}
                    order={ 5 }
                >
                    {content.pages.about.reasons.dev.text}
                </Reason>
                <Reason
                    className='about-reason'
                    title={content.pages.about.reasons.assistance.title}
                    image={require('../../../assets/images/end-to-end.png')}
                    order={ 6 }
                >
                    {content.pages.about.reasons.assistance.text}
                </Reason>
            </div>
        </div>
    );
};

export default AboutReasons;