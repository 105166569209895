import React from 'react';

const PrivacySection = ({title,img,children}) => {
    return (
        <div className='privacy-section'>
            <h2> {title} </h2>
            <div>
                <img src={img} alt="privacy image"/>
                <p> {children} </p>
            </div>
        </div>
    );
};

export default PrivacySection;