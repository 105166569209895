import React from 'react';
import styles from './Person.module.scss';

const Person = ({ name, job, image, className }) => {
    return (
        <div className={ className? styles.container + ' ' + className : styles.container }>
            <div className={ styles.wrapper }>
                <img className={ styles.image } src={ image } alt="person pseudo-image"/>
                <div className={ styles.content }>
                    <h2 className={ styles.name }> { name } </h2>
                    <h3 className={ styles.job }> { job } </h3>
                </div>
            </div>
        </div>
    );
};

export default Person;