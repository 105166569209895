import React from 'react';
import AboutIntroduction from "./AboutIntroduction/AboutIntroduction";
import './About.scss';
import AboutTeam from "./AboutTeam/AboutTeam";
import AboutReasons from "./AboutReasons/AboutReasons";
import Meta from '../../components/Meta';
import useLanguage from './../../hooks/useLanguage';

const About = () => {
    useLanguage({});

    return (
        <div className='about'>
            <Meta slug={'about-us'} />
            <div className='about-main'>
                <div className='about-main-introduction'>
                    <AboutIntroduction />
                </div>
                <AboutTeam />
                <AboutReasons />
            </div>
        </div>
    );
};

export default About;