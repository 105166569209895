import React from 'react';
import { CommonService } from "../../services/common-service";

const BlogContent = ({ blog }) => {
    return (
        <div className='blog-content'>
            <div className='blog-content-paragraph'>
                <h1 className='blog-content-title'> {blog.title} </h1>
                <p dangerouslySetInnerHTML={{ __html: blog.body }} />
            </div>
            <ul className='blog-statistics blog-list'>
                <li>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.7676 12.9141C14.3991 12.0414 13.8645 11.2486 13.1933 10.5801C12.5243 9.90959 11.7317 9.37501 10.8594 9.00586C10.8516 9.00195 10.8437 9 10.8359 8.99609C12.0527 8.11719 12.8437 6.68555 12.8437 5.07031C12.8437 2.39453 10.6758 0.226562 7.99999 0.226562C5.32421 0.226562 3.15624 2.39453 3.15624 5.07031C3.15624 6.68555 3.94725 8.11719 5.16405 8.99805C5.15624 9.00195 5.14843 9.00391 5.14061 9.00781C4.26561 9.37695 3.48046 9.90625 2.80663 10.582C2.13614 11.2511 1.60156 12.0437 1.23241 12.916C0.869758 13.77 0.674171 14.6856 0.656238 15.6133C0.655717 15.6341 0.659373 15.6549 0.666991 15.6743C0.67461 15.6937 0.686037 15.7114 0.700598 15.7263C0.715159 15.7412 0.732561 15.7531 0.751777 15.7612C0.770993 15.7693 0.791636 15.7734 0.812488 15.7734H1.98436C2.0703 15.7734 2.13866 15.7051 2.14061 15.6211C2.17968 14.1133 2.78514 12.7012 3.85546 11.6309C4.96288 10.5234 6.43358 9.91406 7.99999 9.91406C9.56639 9.91406 11.0371 10.5234 12.1445 11.6309C13.2148 12.7012 13.8203 14.1133 13.8594 15.6211C13.8613 15.707 13.9297 15.7734 14.0156 15.7734H15.1875C15.2083 15.7734 15.229 15.7693 15.2482 15.7612C15.2674 15.7531 15.2848 15.7412 15.2994 15.7263C15.3139 15.7114 15.3254 15.6937 15.333 15.6743C15.3406 15.6549 15.3443 15.6341 15.3437 15.6133C15.3242 14.6797 15.1308 13.7715 14.7676 12.9141ZM7.99999 8.42969C7.1035 8.42969 6.25975 8.08008 5.62499 7.44531C4.99022 6.81055 4.64061 5.9668 4.64061 5.07031C4.64061 4.17383 4.99022 3.33008 5.62499 2.69531C6.25975 2.06055 7.1035 1.71094 7.99999 1.71094C8.89647 1.71094 9.74022 2.06055 10.375 2.69531C11.0098 3.33008 11.3594 4.17383 11.3594 5.07031C11.3594 5.9668 11.0098 6.81055 10.375 7.44531C9.74022 8.08008 8.89647 8.42969 7.99999 8.42969Z"
                            fill="white" />
                    </svg>
                    <p> {blog.author} </p>
                </li>
                <li>
                    <div className='list-line' />
                </li>
                <li>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.9151 3.33398H16.1095V4.4451H17.7762V16.6673H2.22063V4.4451H3.8873V3.33398H2.08174C1.95187 3.33615 1.8237 3.36389 1.70455 3.4156C1.58541 3.46731 1.47761 3.54199 1.38733 3.63537C1.29705 3.72875 1.22604 3.839 1.17838 3.95982C1.13071 4.08064 1.10731 4.20967 1.10952 4.33954V16.7729C1.10731 16.9027 1.13071 17.0318 1.17838 17.1526C1.22604 17.2734 1.29705 17.3837 1.38733 17.477C1.47761 17.5704 1.58541 17.6451 1.70455 17.6968C1.8237 17.7485 1.95187 17.7763 2.08174 17.7784H17.9151C18.0449 17.7763 18.1731 17.7485 18.2923 17.6968C18.4114 17.6451 18.5192 17.5704 18.6095 17.477C18.6998 17.3837 18.7708 17.2734 18.8184 17.1526C18.8661 17.0318 18.8895 16.9027 18.8873 16.7729V4.33954C18.8895 4.20967 18.8661 4.08064 18.8184 3.95982C18.7708 3.839 18.6998 3.72875 18.6095 3.63537C18.5192 3.54199 18.4114 3.46731 18.2923 3.4156C18.1731 3.36389 18.0449 3.33615 17.9151 3.33398Z"
                            fill="white" />
                        <path d="M4.44531 7.77734H5.55642V8.88845H4.44531V7.77734Z" fill="white" />
                        <path d="M7.77734 7.77734H8.88845V8.88845H7.77734V7.77734Z" fill="white" />
                        <path d="M11.1094 7.77734H12.2205V8.88845H11.1094V7.77734Z" fill="white" />
                        <path d="M14.4453 7.77734H15.5564V8.88845H14.4453V7.77734Z" fill="white" />
                        <path d="M4.44531 10.5547H5.55642V11.6658H4.44531V10.5547Z" fill="white" />
                        <path d="M7.77734 10.5547H8.88845V11.6658H7.77734V10.5547Z" fill="white" />
                        <path d="M11.1094 10.5547H12.2205V11.6658H11.1094V10.5547Z" fill="white" />
                        <path d="M14.4453 10.5547H15.5564V11.6658H14.4453V10.5547Z" fill="white" />
                        <path d="M4.44531 13.334H5.55642V14.4451H4.44531V13.334Z" fill="white" />
                        <path d="M7.77734 13.334H8.88845V14.4451H7.77734V13.334Z" fill="white" />
                        <path d="M11.1094 13.334H12.2205V14.4451H11.1094V13.334Z" fill="white" />
                        <path d="M14.4453 13.334H15.5564V14.4451H14.4453V13.334Z" fill="white" />
                        <path
                            d="M5.55556 5.55577C5.7029 5.55577 5.84421 5.49724 5.94839 5.39305C6.05258 5.28887 6.11111 5.14756 6.11111 5.00022V1.66688C6.11111 1.51954 6.05258 1.37823 5.94839 1.27405C5.84421 1.16986 5.7029 1.11133 5.55556 1.11133C5.40821 1.11133 5.26691 1.16986 5.16272 1.27405C5.05853 1.37823 5 1.51954 5 1.66688V5.00022C5 5.14756 5.05853 5.28887 5.16272 5.39305C5.26691 5.49724 5.40821 5.55577 5.55556 5.55577Z"
                            fill="white" />
                        <path
                            d="M14.4462 5.55577C14.5935 5.55577 14.7348 5.49724 14.839 5.39305C14.9432 5.28887 15.0017 5.14756 15.0017 5.00022V1.66688C15.0017 1.51954 14.9432 1.37823 14.839 1.27405C14.7348 1.16986 14.5935 1.11133 14.4462 1.11133C14.2988 1.11133 14.1575 1.16986 14.0533 1.27405C13.9492 1.37823 13.8906 1.51954 13.8906 1.66688V5.00022C13.8906 5.14756 13.9492 5.28887 14.0533 5.39305C14.1575 5.49724 14.2988 5.55577 14.4462 5.55577Z"
                            fill="white" />
                        <path d="M7.22266 3.33398H12.7782V4.4451H7.22266V3.33398Z" fill="white" />
                    </svg>
                    <p> {CommonService.fixDate(blog.created_at)} </p>
                </li>
                <li>
                    <div className='list-line' />
                </li>
                <li>
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M4.51614 3.31947C3.21167 4.33295 2.17523 5.54931 1.56264 6.34899L1.5612 6.35088L1.56119 6.35087C1.41684 6.5367 1.33847 6.76531 1.33847 7.00062C1.33847 7.23593 1.41684 7.46454 1.56119 7.65036L1.56265 7.65224L1.56264 7.65224C2.17523 8.45193 3.21167 9.66828 4.51614 10.6818C5.82457 11.6983 7.36684 12.4831 8.99827 12.4831C10.6297 12.4831 12.172 11.6983 13.4804 10.6818C14.7848 9.66831 15.8213 8.45198 16.4339 7.65229C16.7324 7.26244 16.7326 6.7397 16.4337 6.34872C15.8211 5.54904 14.7847 4.33284 13.4804 3.31947C12.172 2.30291 10.6297 1.51816 8.99827 1.51816C7.36684 1.51816 5.82457 2.30291 4.51614 3.31947ZM16.7128 6.13536L16.991 5.92224C16.3594 5.09776 15.2813 3.82995 13.9109 2.76531C12.5446 1.70374 10.8518 0.816406 8.99827 0.816406C7.1447 0.816406 5.45197 1.70374 4.0856 2.76531C2.71578 3.82956 1.63793 5.09683 1.00625 5.92133C0.76673 6.23011 0.636719 6.60981 0.636719 7.00062C0.636719 7.39142 0.766722 7.7711 1.00623 8.07987C1.63791 8.90438 2.71577 10.1717 4.0856 11.2359C5.45197 12.2975 7.1447 13.1848 8.99827 13.1848C10.8518 13.1848 12.5446 12.2975 13.9109 11.2359C15.2813 10.1713 16.3594 8.90347 16.991 8.07899L16.991 8.07894C17.4825 7.43712 17.4823 6.56487 16.9912 5.92251L16.7128 6.13536Z"
                            fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M8.99931 4.85214C7.81239 4.85214 6.85019 5.81434 6.85019 7.00127C6.85019 8.18819 7.81239 9.15039 8.99931 9.15039C10.1862 9.15039 11.1484 8.18819 11.1484 7.00127C11.1484 5.81434 10.1862 4.85214 8.99931 4.85214ZM6.14844 7.00127C6.14844 5.42677 7.42482 4.15039 8.99931 4.15039C10.5738 4.15039 11.8502 5.42677 11.8502 7.00127C11.8502 8.57576 10.5738 9.85214 8.99931 9.85214C7.42482 9.85214 6.14844 8.57576 6.14844 7.00127Z"
                            fill="white" />
                    </svg>
                    <p> {blog.views} </p>
                </li>
            </ul>
        </div>
    );
};

export default BlogContent;