import React from 'react';
import {LanguageContext} from "../../../contexts/LanguageContext";

const Smm = () => {
    const {content} = React.useContext(LanguageContext);
    return (
        <div>
            <div className='services-container'>
                <section>
                    <div className='services-element digital-element'>
                        <div>
                            <h1 className='services-main-title'> SMM </h1>
                            <div className='services-element-content'>
                                <h4> {content.pages.services.digital.advantages} </h4>
                                <ul>
                                    {content.pages.services.digital.smm.advantages.map((advantage, index) =>
                                        <li key={index}> {advantage} </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className='services-image-box'>
                            <img className='services-image global-services-image'
                                 src={require('../../../assets/images/services/smm-first-image.png')} alt="an image"/>
                        </div>
                    </div>
                    <div className='services-element digital-element'>
                        <div className='services-image-box'>
                            <img className='services-image global-services-image'
                                 src={require('../../../assets/images/services/smm-second-image.png')} alt="an image"/>
                        </div>
                        <div className='services-element-content'>
                            <h4> SMM {content.pages.services.digital.package} </h4>
                            <ul>
                                {content.pages.services.digital.smm.package.map((item, index) =>
                                    <li key={index}> {item} </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Smm;