import React, { useContext, useState } from 'react';
import './Blogs.scss';
import BlogLink from "../../components/BlogLink/BlogLink";
import useFetchBlogs from "../../hooks/useFetchBlogs";
import { LanguageContext } from '../../contexts/LanguageContext';
import useFetchBlogsCategories from './../../hooks/useFetchBlogsCategories';
import Dropdown from './../../components/ui/Dropdown/Dropdown';
import useLanguage from './../../hooks/useLanguage';
import Loader from '../../components/Loader/Loader';
import Meta from './../../components/Meta';

const Blogs = () => {
    useLanguage({});
    const { value, content } = useContext(LanguageContext);
    const { blogs, isLoading } = useFetchBlogs(value);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const { categories, isCategoriesLoading, setCategories } = useFetchBlogsCategories({
        initialValue: content.pages.blog.allCategories,
        lang: value
    });

    if (isLoading || isCategoriesLoading) return <Loader />

    return (
        <div className='blogs'>
            <Meta slug='blog-page' />
            <div className='blogs-header'>
                <h1> Blogs </h1>
                <Dropdown
                    data={categories}
                    setCurrentCb={(category) => setSelectedCategoryId(category.id)}
                    displayCb={(category) => category.name}
                    sortCb={(category) => category.id}
                    setData={setCategories}
                />
            </div>
            <div className='blogs-content'>
                {((selectedCategoryId === 0 ? blogs : blogs.filter((blog) => blog.category_id === selectedCategoryId))).map((blog) =>
                    <BlogLink className='blog-link' slug={blog.slug} key={blog.id} image={blog.image}>
                        {blog.title}
                    </BlogLink>
                )}
            </div>
        </div>
    );
};


export default Blogs;
