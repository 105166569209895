import React from "react";
import styles from "./HomeIntroduction.module.scss";
import { useNavigate } from "react-router";
import Button from "../../../components/ui/Button/Button";
import { CommonService } from "../../../services/common-service";
import myVideo from "../../../assets/video/main-video.mp4";
import { LanguageContext } from "../../../contexts/LanguageContext";

const videoId = "QTWGOIJan2Q"
const opts = {
    playerVars: {
        autoplay: 1
    },
}

const HomeIntroduction = () => {
    const { content, value } = React.useContext(LanguageContext);
    const navigate = useNavigate();

    const onClick = () => {
        CommonService.scrollTop();
        navigate(`/${value}/contact-us`);
    };

    return (
        <>
            {/* <YouTube
                videoId={videoId}
                opts={{
                    height: '100%',
                    width: '100%',
                    playerVars: {
                        loop: 1,
                        autoplay: 1,
                        controls: 0,
                        playlist: videoId
                    }
                }}
                className={styles.video}
            /> */}
            <video className={styles.video} autoPlay muted loop>
                <source src={myVideo} type="video/mp4" />
            </video>
            <section className={styles.introduction}>
                {/* <div className={styles.introductionWrapper}>
                    <div className={styles.content}>
                        <h1 className={styles.head}>
                        <allow="autoplay; encrypted-media"
                            cxlassName={styles.greenText}> {content.pages.home.introduction.head.be} </span> {content.pages.home.introduction.head.different}.{" "}
                        </h1>
                        <p className={styles.subhead}>
                            {content.pages.home.introduction.subhead.get} <span
                            className={styles['green-text']}> {content.pages.home.introduction.subhead.more} </span> {content.pages.home.introduction.subhead.leads}
                            <br/>
                            <span
                                className={styles.blueText}> {content.pages.home.introduction.subhead.boost} </span> {content.pages.home.introduction.subhead.rest}
                        </p>
                    </div>
                    
                </div> */}
                <Button
                    className="quote-button"
                    color={'white'}
                    onClick={onClick}
                >
                    {content.pages.home.quote}
                </Button>
            </section>
        </>
    );
};

export default HomeIntroduction;
