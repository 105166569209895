import React from 'react';
import styles from './Header.module.scss'
import Logo from "../../ui/Logo/Logo";
import Menu from "../Menu/Menu";

const Header = ({ className }) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [animation, setAnimation] = React.useState('');

    const onClick = () => {
        setIsMenuOpen(true);
        setAnimation('menu-open')
    }

    return (
        <>
            <header className={styles.header + ` ${className}`}>
                <Logo />
                <svg onClick={onClick} width="52" height="30" viewBox="0 0 52 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="52" height="4.73684" rx="2.36842" fill="white" />
                    <rect x="26" y="12.6318" width="26" height="4.73684" rx="2.36842" fill="white" />
                    <rect y="25.2637" width="52" height="4.73684" rx="2.36842" fill="white" />
                </svg>
            </header>
            <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} animation={animation} setAnimation={setAnimation} />
        </>
    );
};

export default Header;