import React from 'react';
import './RecentPost.scss';
import { useNavigate } from "react-router";
import { useContext } from 'react';
import { LanguageContext } from './../../contexts/LanguageContext';

const RecentPost = ({ profile, title, date, slug }) => {
    const navigate = useNavigate();
    const { value } = useContext(LanguageContext);

    const onClick = () => {
        navigate(`/${value}/blog/` + slug);
    }

    return (
        <div onClick={onClick} className='recent-post'>
            <div className='recent-post-circle back-circle'>
                <img src={profile} alt='profile image' />
            </div>
            <div className='recent-post-info'>
                <p className='recent-post-title'> {title} </p>
                <p className='recent-post-date'> {date} </p>
            </div>
        </div>
    );
};

export default RecentPost;