const getLinkPart = (index) => {
    const partition = getLinkPartition();
    return index > partition.length ? null : partition[index];
}

const getLinkPartition = () => {
    const href = window.location.href;
    const partition = href.split('/');
    return partition;
}

export default {
    getLinkPart,
    getLinkPartition
}