import React from 'react';

const Tech = ({icon, width, height}) => {
    return (
        <div className='tech'>
            <img className='tech-icon' src={icon} style={{width, height}} alt='tech'/>
        </div>
    );
};

export default Tech;