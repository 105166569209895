import React from 'react';
import DigitalWatch from "../../../components/DigitalWatch/DigitalWatch";
import { LanguageContext } from "../../../contexts/LanguageContext";

const HomeEnd = () => {
    const { content } = React.useContext(LanguageContext);
    return (
        <div className='home-end'>
            <h1> {content.pages.home.end.head} </h1>
            <h3> {content.pages.home.end.text1} </h3>
            <DigitalWatch />
            <h3> {content.pages.home.end.text2} </h3>
        </div>
    );
};

export default HomeEnd;