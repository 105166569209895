import React from 'react';
import { LanguageContext } from "../../contexts/LanguageContext";
import Language from "../Language/Language";
import styles from './LanguageSettings.module.scss';
import { useNavigate } from 'react-router';
import helpers from '../../helpers';

const PAGENAME_INDEX = 4;

const sortLanguages = (languages, currentLanguage) => {
    for (let i = 0; i < languages.length; i++) {
        const language = languages[i];
        if (language === currentLanguage) {
            languages[i] = languages[0];
            languages[0] = language;
            break
        }
    }
    return languages;
}

const LanguageSettings = () => {
    const languageContext = React.useContext(LanguageContext);
    const navigate = useNavigate();
    const [firstClick, setFirstClick] = React.useState(true);
    const [displayAll, setDisplayAll] = React.useState(false);
    const languages = languageContext.languages;
    const currentLanguage = languageContext.value;
    let mt = 0;

    const onClick = (language) => {
        if (firstClick) {
            setDisplayAll(true);
        } else {
            languageContext.setValue(language);
            const partition = helpers.getLinkPartition();

            let link = `/${language}`;
            for (let i = PAGENAME_INDEX; i < partition.length; i++) {
                link += `/${partition[i]}`;
            }
            navigate(link);

            setDisplayAll(false);
        }
        setFirstClick(!firstClick)
    }

    return (
        <ul className={styles.languages}>
            {sortLanguages(languages, currentLanguage).map((language) => {
                const isCurrent = language === currentLanguage;
                if (!isCurrent) {
                    mt += 34;
                }
                return (
                    <li key={language}>
                        <Language
                            mt={mt}
                            display={!isCurrent && displayAll}
                            isCurrent={isCurrent}
                            language={language}
                            flag={require(`../../assets/flags/${language}-flag.png`)}
                            onClick={() => onClick(language)}
                            key={language}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

export default LanguageSettings;