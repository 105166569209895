import React from 'react';
import './Loader.scss';

const Loader = () => {
    return (
        <div
            style={{
                backgroundColor: 'black',
                position: 'fixed',
                zIndex: 100,
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>
        </div>
    );
};

export default Loader;