import React from 'react';
import styles from './CurvedLine.module.scss';

const CurvedLine = ({ color, className }) => {
    return (
        <div className={ className? styles.line + ' ' + className : styles.line } style={{
            borderColor: `${ color } transparent transparent transparent`
        }}/>
    );
};

export default CurvedLine;