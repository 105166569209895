import { useNavigate } from "react-router";
import Button from "../../../components/ui/Button/Button";
import { mainGreen } from "../../../constants";
import styles from './ProjectInfo.module.scss';
import { CommonService } from "../../../services/common-service";
import { aosConfig } from "../../Project/ProjectAdditionalData/ProjectAdditionalData";
import { storageURL } from './../../../components/BlogLink/BlogLink';
import { useContext } from 'react';
import { LanguageContext } from './../../../contexts/LanguageContext';

const ProjectInfo = ({ project, reversed, categories }) => {
    const { showcase_image, title, description, slug, category_id } = project;
    const navigate = useNavigate();
    const { value } = useContext(LanguageContext);

    const onClick = () => {
        CommonService.scrollTop();
        navigate(`/${value}/projects/${slug}`);
    }
 
    return (
        <div>
            <div className={styles.container}>
                <img style={{ gridColumn: reversed ? 1 : 2, gridRow: 1 }} src={storageURL + showcase_image} alt="project image" />
                <div style={{ gridColumn: reversed ? 2 : 1, justifyContent: reversed ? 'start' : 'end' }}>
                    <div className={styles.content} {...aosConfig}>
                        <div style={{ textAlign: reversed ? 'start' : 'end' }} className={styles.text}>
                            <h2 className={styles.title}> {title} </h2>
                            <div dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                        <div style={{ justifyContent: reversed ? 'start' : 'end' }} className={styles.buttonBox}>
                            <Button color={mainGreen} onClick={onClick}> View project </Button>
                        </div>
                        <div style={{ justifyContent: reversed ? 'start' : 'end' }} className={styles.categories}>
                            {categories.find((category) => category.id === category_id).title}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default ProjectInfo;