import { useNavigate, useParams } from 'react-router';
import Button from '../../../components/ui/Button/Button';
import { mainGreen } from '../../../constants';
import { CommonService } from '../../../services/common-service.js';
import { useContext } from 'react';
import { LanguageContext } from './../../../contexts/LanguageContext';

const FIRST_PROJECT = 1;
const LAST_PROJECT = 3;

const definePrevAndNext = (currentSlug, slugs, { firstSlug, lastSlug }) => {
    const currentIndex = findCurrentIndex(currentSlug, slugs);
    if (currentIndex === undefined) return;

    let prev = NaN;
    let next = NaN;

    if (currentSlug === firstSlug) {
        next = currentIndex + 1;
    } else if (currentSlug === lastSlug) {
        prev = currentIndex - 1;
    } else {
        next = currentIndex + 1;
        prev = currentIndex - 1;
    }

    return { prev, next }
}

const findFirstAndLastSlugs = (projects) => {
    const slugs = projects.map((project) => project.slug);
    return { firstSlug: slugs[0], lastSlug: slugs[slugs.length - 1], slugs }
}

const findCurrentIndex = (currentSlug, slugs) => {
    for (let i = 0; i < slugs.length; i++) {
        if (currentSlug === slugs[i]) return i;
    }
}

const ProjectNavigation = ({ slug, projects }) => {
    const { firstSlug, lastSlug, slugs } = findFirstAndLastSlugs(projects);
    const { prev, next } = definePrevAndNext(slug, slugs, { firstSlug, lastSlug });
    const { value } = useContext(LanguageContext);
    const navigate = useNavigate();

    const onNext = () => {
        CommonService.scrollTop();
        navigate(`/${value}` + '/projects/' + slugs[next]);
        window.location.reload();
    }

    const onPrev = () => {
        CommonService.scrollTop();
        navigate(`/${value}` + '/projects/' + slugs[prev]);
        window.location.reload();
    }

    return (
        <div className="project-navigation">
            {Number.isNaN(prev) || <div> <Button onClick={onPrev} color={mainGreen} className="project-navigation-prev"> Previus Project </Button> </div>}
            {Number.isNaN(next) || <div> <Button onClick={onNext} color={mainGreen} className="project-navigation-next"> Next Project </Button> </div>}
        </div>
    );
}

export default ProjectNavigation;