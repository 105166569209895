import React, {memo} from 'react';

const PrivacyCard = memo(({id,img,text,current,setCurrent}) => {

    const onClick = () => {
        setCurrent(id);
    }

    return (
        <div onClick={onClick} className={current !== id? 'privacy-card' : 'privacy-card privacy-card-active'}>
            <img src={img} alt="privacy icon"/>
            <p> {text} </p>
        </div>
    );
});

export default PrivacyCard;