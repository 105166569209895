import React from 'react';
import styles from './BlogLink.module.scss';
import { useNavigate } from "react-router";
import { CommonService } from "../../services/common-service";
import { useContext } from 'react';
import { LanguageContext } from './../../contexts/LanguageContext';

export const storageURL = 'https://admin.thejavachip.com/storage/';

const BlogLink = ({ slug, children, className, image }) => {
    const navigate = useNavigate();
    const { value } = useContext(LanguageContext)

    const onClick = () => {
        CommonService.scrollTop();
        navigate(`/${value}/blog/${slug}`);
        // window.location.reload();
    }

    return (
        <div className={styles.container + ` ${className}`}>
            <div className={styles.wrapper} onClick={onClick}>
                <div className={styles.circle}>
                    <img src={storageURL + image} alt="" />
                </div>
                <p className={styles.title}> {children} </p>
            </div>
        </div>
    );
};

export default BlogLink;