import React from 'react';
import Button from "../../../components/ui/Button/Button";
import { mainGreen } from "../../../constants";
import { CommonService } from "../../../services/common-service";
import { useNavigate } from "react-router";

const DigitalCarouselCard = ({ children, branch, title, link, to }) => {
    const navigate = useNavigate()

    const onClick = () => {
        CommonService.scrollTop();
        navigate(to);
    }

    return (
        <div className='digital-carousel-card-container'>
            <div className={`digital-carousel-card`}>
                <div className='digital-carousel-card-wrapper'>
                    <h1> {branch} </h1>
                    <div>
                        <svg width="71" height="226" viewBox="0 0 71 226" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M71 200.197C55.1706 201.902 48.254 197.477 48.254 181.757V143.98C48.254 128.491 42.7996 117.934 32.1693 112.771C42.7996 107.862 48.254 97.559 48.254 81.8165V43.9929C48.254 28.2734 55.2171 23.848 71 25.8072V0.268794C38.1112 -2.19746 21.5159 12.3004 21.5159 44.0621V81.8165C21.5159 97.3054 14.8545 100.74 0 100.002V125.794C14.8545 125.057 21.5159 128.491 21.5159 143.98V181.757C21.5159 213.45 38.1112 228.201 71 225.735V200.197Z" fill="#159939" />
                        </svg>
                        <div className='digital-carousel-card-content'>
                            <h2> {title} </h2>
                            <p> {children} </p>
                        </div>
                    </div>
                    <Button onClick={onClick} color={mainGreen} className='digital-carousel-card-button'> {link} </Button>
                </div>
            </div>
        </div>
    );
};

export default DigitalCarouselCard;