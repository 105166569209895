import React from 'react';
import styles from './DigitalWatch.module.scss';
import { useNavigate } from "react-router";
import { CommonService } from "../../services/common-service";

const fixTime = (num) => {
    return num < 10 ? `0${num}` : num;
}

const DigitalWatch = ({ className }) => {
    const [hours, setHours] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);
    const navigate = useNavigate();

    React.useEffect(() => {
        setInterval(() => {
            const currentDate = new Date();
            setHours(currentDate.getHours());
            setMinutes(currentDate.getMinutes());
            setSeconds(currentDate.getSeconds());
        }, 1000);
    }, []);

    const onClick = () => {
        CommonService.scrollTop();
        navigate('/contact-us')
    }

    return (
        <div onClick={onClick} className={className ? styles.container + ' ' + className : styles.container}>
            <p className={styles.time}>
                {fixTime(hours)} : {fixTime(minutes)} : {fixTime(seconds)}
            </p>
        </div>
    );
};

export default DigitalWatch;