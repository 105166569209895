import React from 'react';
import DigitalCarouselCard from "./DigitalCarouselCard";
import Button from "../../../components/ui/Button/Button";
import { LanguageContext } from "../../../contexts/LanguageContext";

const variants = {
    next: {
        1: 2,
        2: 3,
        3: 1
    },
    prev: {
        1: 3,
        3: 2,
        2: 1
    }
}

const swapRef = (ref1, ref2) => {
    const temp = ref1.current;
    ref1.current = ref2.current;
    ref2.current = temp;

    return [ref1, ref2]
}

const HomeDigitalCarousel = () => {
    const [current, setCurrent] = React.useState(1);
    const { content, value } = React.useContext(LanguageContext);
    let leftRef = React.useRef(null);
    let currentRef = React.useRef(null);
    let rightRef = React.useRef(null);

    const onNextClick = () => {
        setCurrent(variants.next[current]);
        console.log('click');

        leftRef.current.className = 'middle-card';
        currentRef.current.className = 'right-card';
        rightRef.current.className = 'left-card';

        [rightRef, currentRef] = swapRef(rightRef, currentRef);
        [leftRef, currentRef] = swapRef(leftRef, currentRef);
    }

    const onPrevClick = () => {
        setCurrent(variants.prev[current]);

        leftRef.current.className = 'right-card';
        currentRef.current.className = 'left-card'
        rightRef.current.className = 'middle-card';

        [leftRef, currentRef] = swapRef(leftRef, currentRef);
        [rightRef, currentRef] = swapRef(rightRef, currentRef);
    }

    return (
        <div className='home-digital-carousel'>
            <div className='left-card' ref={leftRef}>
                <DigitalCarouselCard
                    branch='SEO'
                    title={content.pages.home.digital.seo.head}
                    link={content.pages.home.digital.seo.button}
                    to={`/${value}/services/digital-marketing`}
                >
                    {content.pages.home.digital.seo.subhead}
                </DigitalCarouselCard>
            </div>
            <Button color='white' className='left-arrow' onClick={onPrevClick} />
            <div className='middle-card' ref={currentRef}>
                <DigitalCarouselCard
                    branch='SMM'
                    title={content.pages.home.digital.smm.head}
                    link={content.pages.home.digital.smm.button}
                    to={`/${value}/services/digital-marketing`}
                >
                    {content.pages.home.digital.smm.subhead}
                </DigitalCarouselCard>
            </div>
            <Button color='white' className='right-arrow' onClick={onNextClick} />
            <div className='right-card' ref={rightRef}>
                <DigitalCarouselCard
                    branch='SEM'
                    title={content.pages.home.digital.sem.head}
                    link={content.pages.home.digital.sem.button}
                    to={`/${value}/services/digital-marketing`}
                >
                    {content.pages.home.digital.sem.subhead}
                </DigitalCarouselCard>
            </div>
        </div>
    );
};

export default HomeDigitalCarousel;