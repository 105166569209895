import React from 'react';
import styles from './ListPoint.module.scss';

const ListPoint = ({ className, order }) => {
    return (
        <div className={ className? styles.outerCircle + ' ' + className : styles.outerCircle }>
            <div className={ styles.innerCircle }>
                <span className={ styles.number }> {`{ ${order} }`} </span>
            </div>
        </div>
    );
};

export default ListPoint;