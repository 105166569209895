
import { useContext, useEffect } from 'react';
import helpers from '../helpers';
import { LanguageContext, languages } from './../contexts/LanguageContext';
import { useNavigate } from 'react-router';

const useLanguage = ({ linkPart = 3 }) => {
    const { setValue } = useContext(LanguageContext)
    const navigate = useNavigate();

    useEffect(() => {
        const linkLang = helpers.getLinkPart(linkPart);
        if (!languages.some((lang) => lang === linkLang)) navigate('/error');
        setValue(linkLang);
    }, []);

    return {};
}

export default useLanguage;