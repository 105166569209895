import React from 'react';
import styles from './Modal.module.scss';

const Modal = ({modal, setModal, children}) => {

    const onShadowClick = () => {
        setModal(false);
    }

    const onContainerClick = (e) => {
        e.stopPropagation();
    }

    return (
        <>
            {modal &&
                <div className={styles.shadow} onClick={onShadowClick}>
                    <div className={styles.container} onClick={onContainerClick}>
                        {children}
                    </div>
                </div>
            }
        </>
    );
};

export default Modal;