import React from 'react';
import CurvedLine from "../../../components/CurvedLine/CurvedLine";
import Tech from "./Tech";
import Carousel from "react-multi-carousel";
import {LanguageContext} from "../../../contexts/LanguageContext";

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1400},
        items: 10
    },
    largeTablet: {
        breakpoint: {max: 1400, min: 1024},
        items: 8
    },
    tablet: {
        breakpoint: {max: 1024, min: 760},
        items: 6
    },
    largeMobile: {
        breakpoint: {max: 760, min: 500},
        items: 4
    },
    mobile: {
        breakpoint: {max: 500, min: 0},
        items: 3
    }
};

const HomeTechs = () => {
    const {content} = React.useContext(LanguageContext);
    return (
        <>
            <CurvedLine color='#989B09'/>
            <div className='home-techs'>
                <h1 className='home-techs-title'> {content.pages.home.titles.techs} </h1>
                <p> {content.pages.home.techs} </p>
                <Carousel
                    containerClass='multi-carousel-wrapper'
                    responsive={responsive}
                    autoPlay={true}
                    infinite={true}
                    arrows={false}
                    showDots={false}
                    pauseOnHover={false}
                >
                    <Tech icon={require('../../../assets/icons/techs/android-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/angular-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/cordova-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/css-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/firebase-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/html-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/ionic-logo-transparent.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/ios-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/java-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/jquery-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/js-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/kotlin-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/mongodb-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/mysql-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/nodejs-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/php.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/react-transparent-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/react-native-transparent-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/rxjava-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/sqlite-logo-transparent.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/swift-logo.png')}/>
                    <Tech icon={require('../../../assets/icons/techs/xcode-logo.png')}/>
                </Carousel>
            </div>
        </>
    );
};


export default HomeTechs;