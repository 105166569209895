import React from 'react';
import styles from './Menu.module.scss';
import { NavLink } from "react-router-dom";
import { CommonService } from "../../../services/common-service";
import LanguageSettings from "../../LanguageSettings/LanguageSettings";
import { LanguageContext } from "../../../contexts/LanguageContext";
import { mainGreen } from "../../../constants";

const Menu = ({ isMenuOpen, setIsMenuOpen, animation, setAnimation }) => {
    const { content, value } = React.useContext(LanguageContext);

    const onClose = () => {
        setIsMenuOpen(false);
        setAnimation('menu-close')
    }

    const onShadowClick = () => {
        onClose();
    }

    const onLinkClick = () => {
        CommonService.scrollTop();
        onClose();
    }

    return (
        <>
            <div className={styles.container + ` ${styles[animation]}`}>
                {isMenuOpen &&
                    <svg onClick={onClose} width="52" height="40" viewBox="0 0 52 40" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.0006 38.6229C7.51766 39.0376 4.75102 36.5572 4.75102 31.2282V24.8684C4.75102 22.264 3.6405 21.6865 1.16406 21.8105V17.4737C3.6405 17.5978 4.75102 17.0203 4.75102 14.4159V8.05604C4.75102 2.7271 7.51766 0.304845 13.0006 0.692404V4.99819C10.3617 4.66877 9.20473 5.41289 9.20473 8.05604V14.4159C9.20473 17.0629 8.29929 18.7953 6.52709 19.6208C8.29929 20.489 9.20473 22.264 9.20473 24.8684V31.2205C9.20473 33.8637 10.3656 34.6078 13.0006 34.321V38.6229Z"
                            fill={mainGreen} stroke={mainGreen} strokeMiterlimit="10" />
                        <path
                            d="M38.9844 34.3203C41.6233 34.6071 42.7764 33.863 42.7764 31.2199V24.8678C42.7764 22.2634 43.6857 20.4883 45.4579 19.6202C43.6857 18.7947 42.7764 17.0623 42.7764 14.4153V8.05541C42.7764 5.41225 41.6156 4.66814 38.9844 4.99756V0.7034C44.4674 0.28871 47.234 2.72646 47.234 8.06704V14.4153C47.234 17.0197 48.3445 17.5971 50.821 17.4731V21.8099C48.3445 21.6859 47.234 22.2634 47.234 24.8678V31.2199C47.234 36.5488 44.4674 39.0292 38.9844 38.6145V34.3203Z"
                            fill={mainGreen} stroke={mainGreen} strokeMiterlimit="10" />
                    </svg>
                }
                <nav className={styles.nav}>
                    <NavLink to={`/${value}`} className={styles.link + ` ${styles.link1}`}
                        onClick={onLinkClick}> {content.header.home} </NavLink>
                    <NavLink to={`/${value}/portfolio`} className={styles.link + ` ${styles.link2}`}
                        onClick={onLinkClick}> {content.header.portfolio} </NavLink>
                    <NavLink to={`/${value}/services`} className={styles.link + ` ${styles.link3}`}
                        onClick={onLinkClick}> {content.common.services} </NavLink>
                    <NavLink to={`/${value}/contact-us`} className={styles.link + ` ${styles.link4}`}
                        onClick={onLinkClick}> {content.common.contact} </NavLink>
                    <NavLink to={`/${value}/about-us`} className={styles.link + ` ${styles.link5}`}
                        onClick={onLinkClick}> {content.common.about} </NavLink>
                    <NavLink to={`/${value}/privacy-policy`} className={styles.link + ` ${styles.link6}`}
                        onClick={onLinkClick}> {content.common.privacy} </NavLink>
                    <NavLink to={`/${value}/FAQ`} className={styles.link + ` ${styles.link7}`}
                        onClick={onLinkClick}> {content.common.faq} </NavLink>
                    <NavLink to={`/${value}/blogs`} className={styles.link + ` ${styles.link8}` + ` ${styles.blogs}`}
                        onClick={onLinkClick}> {content.common.blog} </NavLink>
                </nav>
                <LanguageSettings />
            </div>
            {isMenuOpen && <div onClick={onShadowClick} className={styles.shadow} />}
        </>
    );
};

export default Menu;