import { useState, useEffect } from 'react';
import { apiBaseURL } from './../constants/index';
import axios from 'axios';

const useFetchBlogsCategories = ({ initialValue, lang }) => {
    const [categories, setCategories] = useState([]);
    const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchBlogsCategories = async () => {
        const url = apiBaseURL + `/categories?lang=${lang}`;
        try {
            const response = await axios.get(url);
            const categories = response.data.data;

            categories.unshift({ name: initialValue, id: 0 })
            setCategories(categories);
        } catch (error) {
            setError(error);
        } finally {
            setIsCategoriesLoading(false);
        }
    }

    useEffect(() => {
        fetchBlogsCategories();
    }, [initialValue, lang]);

    return { categories, isCategoriesLoading, error, setCategories };
}

export default useFetchBlogsCategories;