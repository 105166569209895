import { useState, useEffect } from 'react';
import { apiBaseURL } from './../constants/index';
import axios from 'axios';

const useFetchProjects = (lang) => {
    const [isProjectsLoading, setIsProjectsLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState(null);

    const fetchProjects = async () => {
        const url = apiBaseURL + `/portfolios?lang=${lang}`;
        try {
            const resposne = await axios.get(url);
            setProjects(resposne.data.data);
        } catch (error) {
            setError(error);
        } finally {
            setIsProjectsLoading(false);
        }
    }

    useEffect(() => {
        fetchProjects();
    }, [lang])

    return { projects, error, isProjectsLoading };
}

export default useFetchProjects;