import { useContext } from "react";
import { useParams } from "react-router";
import ProjectHero from "./ProjectHero/ProjectHero";
import ProjectAdditionalData from "./ProjectAdditionalData/ProjectAdditionalData";
import ProjectSection from "./ProjectSection/ProjectSection";
import ProjectNavigation from "./ProjectsNavigation/ProjectsNavigation";
import ProjectContact from "./ProjectContact/ProjectContact";
import './Projects.scss';
import useFetchProject from './../../hooks/useFetchProject';
import { LanguageContext } from './../../contexts/LanguageContext';
import { storageURL } from './../../components/BlogLink/BlogLink';
import { AxiosError } from 'axios'
import { Navigate } from 'react-router-dom';
import useLanguage from './../../hooks/useLanguage';
import useFetchProjects from './../../hooks/useFetchProjects';
import Loader from "../../components/Loader/Loader";
import StaticMeta from './../../components/StaticMeta';

const Project = () => {
    useLanguage({});
    const { slug } = useParams();
    const { value } = useContext(LanguageContext);

    const { isLoading, project, error } = useFetchProject({ slug, lang: value });
    const { isProjectsLoading, projects } = useFetchProjects(value);
    if (isLoading || isProjectsLoading) return <Loader />

    if (error && error instanceof AxiosError) {
        if (error.response.status === 404) {
            return <Navigate to='/error' />
        }
    }

    return (
        <div className="project">
            <StaticMeta meta={project.meta} />
            <div>
                <ProjectHero image={`url(${storageURL + project.cover})`} title={project.title} website={project.website} />
                <div className="project-container" style={{
                    backgroundImage: `url(${storageURL + project.showcase_image})`
                }}>
                    <ProjectAdditionalData description={project.description} year={project.year} services={project.services} country={project.country} />
                    <ProjectSection
                        image={project.images[0]}
                        text={project.texts[0]}
                        order={3}
                    />
                    <div className="project-empty" />

                    {[1, 2, 3, 4, 5].map((num) => {
                        const current = num;
                        return (
                            <ProjectSection
                                image={project.images[num]}
                                key={num}
                                text={project.texts[num]}
                                order={current === 3 ? current : current % 3}
                            />
                        )
                    })}
                </div>
                <ProjectNavigation slug={slug} projects={projects} /> {/* FIXME: navigation does not work yet */}
                <ProjectContact />
            </div>
        </div >
    );
}

export default Project;