import Form from '../../../components/ui/Form/Form';
import { aosConfig } from './../ProjectAdditionalData/ProjectAdditionalData';
import Icon from '../../../assets/mms.svg';


const ProjectContact = () => {
    return (
        <div className="project-contact">
            <h2 {...aosConfig}>
                Let’s create
                progress together
            </h2>
            <div className="project-wrapper" {...aosConfig}>

                <div className="project-contact-image">
                    <img src={Icon}
                         alt="contact-us-icon" 
                         style={{ height: '30rem', width: '100%', paddingRight: '1rem' }}></img>
                </div>

                {/* <Icon className='project-contact-icon' /> */}

                <Form className='project-contact-form' />
            </div>
        </div>
    );
}

export default ProjectContact;