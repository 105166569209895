import React from 'react';
import RecentPost from "./RecentPost";
import Search from "../../components/Search/Search";
import useFetchBlogs from "../../hooks/useFetchBlogs";
import { CommonService } from "../../services/common-service";
import Loader from "../../components/Loader/Loader";
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';

const BlogAside = ({ currentBlogTitle }) => {
    const { value, content } = useContext(LanguageContext);
    const { blogs, isLoading } = useFetchBlogs(value);

    if (isLoading) return <Loader />

    return (
        <div className='blog-aside'>
            <Search />
            <div className='recent-posts'>
                <p className='blog-aside-title'> {content.pages.blog.recentPosts} </p>
                {blogs.length !== 1 &&
                    <div>
                        {(blogs.length >= 3 ? [0, 1, 2] : CommonService.getNumbersTo(blogs.length)).map((num) => {
                            const blog = blogs[num];
                            const name = blog.author.split(' ')[1].toLowerCase();
                            if (blog.title !== currentBlogTitle) {
                                return (
                                    <div key={blog.id}>
                                        <RecentPost
                                            profile={require(`../../assets/images/team/${name}.png`)}
                                            title={blog.title}
                                            date={CommonService.fixDate(blog.created_at)}
                                            slug={blog.slug}
                                            key={blog.title}
                                        />
                                    </div>
                                )
                            }
                        })}
                    </div>
                }
            </div>
        </div>
    );
};

export default BlogAside;