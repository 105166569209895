import React from "react";
import styles from "./Footer.module.scss";
import Logo from "../../ui/Logo/Logo";
import { NavLink } from "react-router-dom";
import { CommonService } from "../../../services/common-service";
import { LanguageContext } from "../../../contexts/LanguageContext";

const Footer = ({ className }) => {
  const { content, value } = React.useContext(LanguageContext);

  const onLinkClick = () => {
    CommonService.scrollTop();
  };

  return (
    <div
      className={
        className ? styles.container + " " + className : styles.container
      }
    >
      <div className={styles.empty} />
      <footer className={styles.footer}>
        <div className={styles.logoBox}>
          <Logo className={styles.logo} />
          <p className={styles.headLine}> {content.footer.logo.head} </p>
        </div>
        <div className={styles.listBox + " " + styles.quickLinksBox}>
          <h3 className={styles.quickLinksTitle}>
            {content.footer.titles.quickLinks}
          </h3>
          <ul className={styles.quickLinks}>
            <li className={styles.quickLinksListItem}>
              <NavLink
                className={styles.link}
                to={`/${value}/services`}
                onClick={onLinkClick}
              >
                {content.common.services}
              </NavLink>
            </li>
            <li className={styles.quickLinksListItem}>
              <NavLink
                className={styles.link}
                to={`/${value}/portfolio`}
                onClick={onLinkClick}
              >
                {content.header.portfolio}
              </NavLink>
            </li>
            <li className={styles.quickLinksListItem}>
              <NavLink
                className={styles.link}
                to={`/${value}/about-us`}
                onClick={onLinkClick}
              >
                {content.common.about}
              </NavLink>
            </li>
            <li className={styles.quickLinksListItem}>
              <NavLink
                className={styles.link}
                to={`/${value}/contact-us`}
                onClick={onLinkClick}
              >
                {content.common.contact}
              </NavLink>
            </li>
            <li className={styles.quickLinksListItem}>
              <NavLink
                className={styles.link}
                to={`/${value}/privacy-policy`}
                onClick={onLinkClick}
              >
                {content.common.privacy}
              </NavLink>
            </li>
            <li className={styles.quickLinksListItem}>
              <NavLink
                className={styles.link}
                to={`/${value}/faq`}
                onClick={onLinkClick}
              >
                {content.common.faq}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={styles.listBox}>
          <h3 className={styles.contactUsTitle}> {content.common.contact} </h3>
          <div className={styles.twoLists}>
            <ul className={styles.list1}>
              <li className={styles.contactUsListItem}>
                <img
                  src={require("../../../assets/icons/phone.png")}
                  width={"18px"}
                  alt="a phone icon"
                />
              </li>
              <li className={styles.contactUsListItem}>
                <img
                  src={require("../../../assets/icons/email.png")}
                  width={"18px"}
                  alt="a phone icon"
                />
              </li>
              <li className={styles.contactUsListItem}>
                <img
                  src={require("../../../assets/icons/address.png")}
                  width={"20px"}
                  style={{
                    position: "relative",
                    right: "1px",
                  }}
                  alt="a phone icon"
                />
              </li>
            </ul>
            <ul className={styles.list2}>
              <li className={styles.contactUsListItem}>
                <a className={styles.contactLink} href="tel:+994998822500">
                  +994998822500
                </a>
              </li>
              <li className={styles.contactUsListItem}>
                <a
                  target="_top"
                  className={styles.contactLink}
                  href="mailto:office@thejavachip.com"
                >
                  office@thejavachip.com
                </a>
              </li>
              <li className={styles.contactUsListItem}>
                <a
                  href="https://www.google.com/maps/place/The+Javachip+LLC/@40.3655367,49.8309266,17z/data=!3m1!4b1!4m5!3m4!1s0x40307de589f96f27:0x30fb1daa2ffcbc45!8m2!3d40.3655326!4d49.8331153"
                  className={styles.contactLink}
                >
                  İçərişəhər 18, İlyas Əfəndiyev küç
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.listBox}>
          <h3 className={styles.socialMediaTitle}>
            {" "}
            {content.footer.titles.social}{" "}
          </h3>

          <div className={styles.socialIcons}>
            <div className={styles.socialIconsItem} data-name="facebook">
              <a
                href="https://www.facebook.com/thejavachipcompany"
                className={styles.socialIconsItemHref}
                target="_blank"
              >
                <svg width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="#101010"
                    d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"
                  ></path>
                </svg>
              </a>
            </div>
            <div className={styles.socialIconsItem} data-name="twitter">
              <a href="https://www.twitter.com/Javachip_Global"
                className={styles.socialIconsItemHref}
                target="_blank">
                <svg width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="#101010"
                    d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814a11.874 11.874 0 0 1-8.62-4.37a4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101a4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732a11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9c0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"
                  ></path>
                </svg>
              </a>
            </div>
            <div className={styles.socialIconsItem} data-name="instagram">
              <a
                href="https://www.instagram.com/the.javachip.global/"
                className={styles.socialIconsItemHref}
                target="_blank"
              >
                <svg width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="#101010"
                    d="M12 9a3 3 0 1 0 0 6a3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10a5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0a1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058c-.784.037-1.31.142-1.798.332a2.886 2.886 0 0 0-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029c.037.783.142 1.31.331 1.797c.17.435.37.748.702 1.08c.337.336.65.537 1.08.703c.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058c.782-.037 1.309-.142 1.797-.331a2.92 2.92 0 0 0 1.08-.702c.337-.337.538-.65.704-1.08c.19-.493.296-1.02.332-1.8c.052-1.104.058-1.49.058-4.029c0-2.474-.007-2.878-.058-4.029c-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08a2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06c1.065.05 1.79.217 2.428.465c.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428c.047 1.066.06 1.405.06 4.122c0 2.717-.01 3.056-.06 4.122c-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772a4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465c-1.066.047-1.405.06-4.122.06c-2.717 0-3.056-.01-4.122-.06c-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153a4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122c.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"
                  ></path>
                </svg>
              </a>
            </div>
            <div className={styles.socialIconsItem} data-name="youtube">
              <a
                href="https://www.youtube.com/channel/UCzIPQ3A6jWxs_SUPD_Jr2KQ"
                className={styles.socialIconsItemHref}
                target="_blank"
              >
                <svg width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="#101010"
                    d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5l-6-3.5v7z"
                  ></path>
                </svg>
              </a>
            </div>
            <div className={styles.socialIconsItem} data-name="linkedin">
              <a
                href="https://www.linkedin.com/company/the-javachip/"
                className={styles.socialIconsItemHref}
                target="_blank"
              >
                <svg width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="#101010"
                    d="M6.94 5a2 2 0 1 1-4-.002a2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className={styles.cite}>
          <img
            className={styles.citeIcon}
            src={require("../../../assets/icons/cite.png")}
            alt="icon"
          />
          <p className={styles.citeText}> JAVACHIP 2022 </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
