import React from 'react';
import './Contact.scss';
import ContactAside from "./ContactAside";
import ContactForm from "./ContactForm";
import ContactMap from "./ContactMap";
import { LanguageContext } from "../../contexts/LanguageContext";
import Meta from './../../components/Meta';
import useLanguage from './../../hooks/useLanguage';

const Contact = () => {
    useLanguage({});
    const { content } = React.useContext(LanguageContext);
    return (
        <div className='contact'>
            <Meta slug='contact-us' />
            <section className='contact-main-section'>
                <ContactAside />
                <ContactForm />
            </section>
            <h1> {content.pages.contact.title}! </h1>
            <ContactMap />
        </div>
    );
};

export default Contact;