import React from 'react';
import styles from './Logo.module.scss';
import Icon from "./javachip-logo-footer.svg";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { useContext } from 'react';
import { LanguageContext } from './../../../contexts/LanguageContext';
import { CommonService } from '../../../services/common-service';

const Logo = ({ className }) => {
    const { value } = useContext(LanguageContext);

    const onClick = () => {
        CommonService.scrollTop();
    }

    return (
        <NavLink to={`/${value}`} onClick={onClick}>
            <img src={Icon} alt="The Javachip | Digital Solutions Company Logo"
                className={className ? styles.box + ' ' + className : styles.box} />
        </NavLink>
    );
};

export default Logo;