import CurvedLine from '../../components/CurvedLine/CurvedLine';
import ProjectInfo from './ProjectInfo/ProjectInfo.jsx';
import './Portfolio.scss';
import useFetchProjects from './../../hooks/useFetchProjects';
import useFetchProjectsCategories from './../../hooks/useFetchProjectsCategories';
import { useContext } from 'react';
import { LanguageContext } from './../../contexts/LanguageContext';
import useLanguage from './../../hooks/useLanguage';
import Loader from '../../components/Loader/Loader';
import Meta from './../../components/Meta';
const blue = "#28ac94";

const Portfolio = () => {
    useLanguage({});
    const { value } = useContext(LanguageContext);
    const { isProjectsLoading, projects } = useFetchProjects(value);
    const { isCategoriesLoading, categories } = useFetchProjectsCategories(value);

    if (isProjectsLoading || isCategoriesLoading) return <Loader />

    return (
        <div className="portfolio">
            <Meta slug='portfolio-page' />
            <div className='portfolio-header'>
                <CurvedLine color={blue} />
                <h1> Portfolio </h1>
            </div>
            {projects.map((project, index) =>
                <ProjectInfo
                    key={project.id}
                    reversed={index % 2 !== 0}
                    project={project}
                    categories={categories}
                />
            )}
        </div>
    );
}

export default Portfolio;