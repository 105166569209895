import React from 'react';
import { BlogsService } from "../services/blogs-service";
import { CommonService } from "../services/common-service";

const useFetchBlogs = (language) => {
    const [blogs, setBlogs] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const fetchData = async () => {
        try {
            const response = await BlogsService.findAll(language);
            const blogs = response.data.data;
            setBlogs(blogs);
        } catch (error) {
            alert(CommonService.getErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        fetchData().catch(console.error);
    }, [language])

    return { blogs, isLoading }
};

export default useFetchBlogs;