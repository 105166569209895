import React from 'react';
import './Error.scss';

const Error = () => {
    return (
        <div className='error'>
            <div className="error-text">
                <h1 className="error-text-h1">404</h1>

                <div className="machine">
                    <div className="machine-top">
                        <div className="machine-middle">

                        </div>
                    </div>
                    <div className="machine-bottom"/>
                    <div className="hand"/>
                    <div className="left-hand"/>
                    <div className="coffee"/>
                </div>
            </div>
        </div>
    );
};

export default Error;