import React from 'react';
import styles from './Search.module.scss';
import { BlogsService } from "../../services/blogs-service";
import { CommonService } from "../../services/common-service";
import SearchedItem from "../SearchedItem/SearchedItem";
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';

const Search = () => {
    const { content } = useContext(LanguageContext)
    const [search, setSearch] = React.useState(`${content.pages.blog.search.toUpperCase()}...`);
    const [firstClick, setFirstClick] = React.useState(true);
    const [searchedData, setSearchedItems] = React.useState([]);

    const onSearchClick = () => {
        if (firstClick) {
            setSearch('');
            setFirstClick(false);
        }
    }

    const onSearch = async (e) => {
        e.preventDefault();
        try {
            const response = await BlogsService.search(search);
            setSearchedItems(response.data.blogs);
        } catch (error) {
            alert(CommonService.getErrorMessage(error));
        }
    }

    const onChange = (e) => {
        setSearch(e.target.value);
    }

    return (
        <>
            <form onSubmit={onSearch} className={styles.container}>
                <input
                    type="text"
                    placeholder={`${content.pages.blog.search.toUpperCase()}...`}
                    value={search}
                    onChange={onChange}
                    onClick={onSearchClick}
                />

                <button> {content.pages.blog.search} </button>
            </form>
            <div className={styles.list}>
                {searchedData.length !== 0 &&
                    searchedData.map((blog) => {
                        return <SearchedItem
                            id={blog.id}
                            title={blog.title}
                            key={blog.id}
                        />
                    })
                }
            </div>
        </>
    );
};

export default Search;