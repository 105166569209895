import { Helmet } from 'react-helmet';
import { useContext } from 'react';
import { LanguageContext } from './../contexts/LanguageContext';
import useMetaData from './../hooks/useMetaData';
import Loader from './Loader/Loader';

const Meta = ({ slug }) => {
    const { value } = useContext(LanguageContext);
    const { meta, isMetaLoading } = useMetaData(slug, value);

    if (isMetaLoading) return <Loader />

    return (
        <Helmet>
            <title> {`${meta.meta_title}`} </title>
            <meta name='keywords' content={`${meta.meta_keywords}`} />
            <meta name='description' content={`${meta.meta_description}`} />
        </Helmet>
    );
}

export default Meta;