import React from 'react';
import { LanguageContext } from "../../contexts/LanguageContext";
import ServicesNav from './ServicesNav';
import useLanguage from '../../hooks/useLanguage';
import Meta from './../../components/Meta';

const CyberSecurity = () => {
    useLanguage({});
    const { content } = React.useContext(LanguageContext);
    return (
        <>
            <ServicesNav current={4} />
            <Meta slug='cyber-security' />
            <div className='cyber-security'>
                <div className='services-container'>
                    <section>
                        <div className='services-element'>
                            <div>
                                <h1 className='services-main-title'> {content.common.cyber} </h1>
                                <p> {content.pages.services.cyber.text1} </p>
                            </div>
                            <div className='services-image-box'>
                                <img className='services-image cyber-security-image global-services-image'
                                    src={require('../../assets/images/services/cyber-1.png')} alt="an image" />
                            </div>
                        </div>
                        <div className='services-element'>
                            <div className='services-image-box'>
                                <img className='services-image global-services-image'
                                    src={require('../../assets/images/services/cyber-2.png')} alt="an image" />
                            </div>
                            <p> {content.pages.services.cyber.text2} </p>
                        </div>
                    </section>
                    <section className='services-list-box'>
                        <h1> {content.common.why} </h1>
                        <ul className='services-why-list'>
                            {content.pages.services.cyber.reasons.map((reason, index) =>
                                <li key={index}> {reason} </li>
                            )}
                        </ul>
                    </section>
                </div>
            </div>
        </>
    );
};

export default CyberSecurity;