import React from 'react';
import CurvedLine from "../../../components/CurvedLine/CurvedLine";
import { mainGreen } from "../../../constants";
import HomeDigitalCarousel from "./HomeDigitalCarousel";
import { LanguageContext } from "../../../contexts/LanguageContext";

const HomeDigital = () => {
    const { content } = React.useContext(LanguageContext);
    return (
        <div>
            <div className='home-digital'>
                <div className='home-digital-header'>
                    <CurvedLine color={mainGreen} />
                    <h1 className='home-digital-title'> {content.common.digital} </h1>
                </div>
                <HomeDigitalCarousel />
            </div>
        </div>
    );
};

export default HomeDigital;