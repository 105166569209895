import React from 'react';
import styles from './Reason.module.scss';
import ListPoint from "../ListPoint/ListPoint";

const Reason = ({ title, className, children, image, order }) => {
    return (
        <div className={ styles.container + ` ${className}`}>
            <div className={ styles.listStyle }>
                <ListPoint className={ styles.point } order={ order }/>
                {order !== 6? <hr className={ styles.line }/> : undefined}
            </div>
            <div className={ styles.content }>
                <h2 className={ styles.title }> { title } </h2>
                <p className={ styles.text }> { children } </p>
                <img className={ styles.image } src={ image } alt="a background image"/>
            </div>
        </div>
    );
};

export default Reason;