import React, { useContext } from 'react';
import BlogLink from "../../components/BlogLink/BlogLink";
import Carousel from "react-multi-carousel";
import useFetchBlogs from '../../hooks/useFetchBlogs';
import { LanguageContext } from '../../contexts/LanguageContext';
import Loader from '../../components/Loader/Loader';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1160 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1160, min: 760 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 760, min: 0 },
        items: 1
    }
}

const RelatedPosts = ({ currentCategoryId, currentId }) => {
    const { value, content } = useContext(LanguageContext);
    const { blogs, isLoading } = useFetchBlogs(value);

    if (isLoading) return <Loader />


    return (
        <div>
            <h1 className='related-posts-title'> {content.pages.blog.relatedPosts} </h1>
            {blogs.length !== 0 &&
                <Carousel
                    containerClass='multi-carousel-wrapper'
                    responsive={responsive}
                    swipeable={true}
                    arrows={true}
                >
                    {blogs.map((blog) => {
                        if (blog.category_id === currentCategoryId && blog.id !== currentId) {

                            return (
                                <BlogLink
                                    slug={blog.slug}
                                    key={blog.id}
                                    image={blog.image}
                                > {blog.title} </BlogLink>
                            )
                        }
                    })}
                </Carousel>
            }
        </div>
    );
};

export default RelatedPosts;