import React, { memo, useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import { useNavigate } from "react-router";
import Topic from "./Topic";

import { FacebookShareButton, LinkedinShareButton } from "react-share";

const divideName = (name) => {
    const len = name.length;
    const splitIndex = Math.floor(len / 2);
    let firstPart = "";
    let secondPart = "";

    for (let i = 0; i < len; i++) {
        const char = name[i];
        if (i < splitIndex) {
            firstPart += char;
        } else {
            secondPart += char;
        }
    }

    return { firstPart, secondPart };
};

const BlogHero = memo(({ author, topic, slug }) => {
    const { content } = useContext(LanguageContext)
    const navigate = useNavigate();
    const [firstPart, setFirstPart] = React.useState("");
    const [secondPart, setSecondPart] = React.useState("");
    const [image, setImage] = React.useState("");
    const { value } = useContext(LanguageContext)

    React.useEffect(() => {
        const name = author.name.split(" ")[1];
        const { firstPart, secondPart } = divideName(name);
        setImage(require(`../../assets/images/team/${name.toLowerCase()}.png`));
        setFirstPart(firstPart);
        setSecondPart(secondPart);
    }, [value]);

    const shareUrl = window.location.href;

    return (
        <>
            <div className="blog-hero">
                <div className="blog-profile">
                    <p className="blog-profile-name first-part"> {firstPart} </p>
                    <div className="blog-hero-circle back-circle">
                        <img
                            className="blog-profile-image"
                            src={image}
                            alt="profile-image"
                        />
                        <p className="blog-profile-profession"> {author.profession} </p>
                    </div>
                    <p className="blog-profile-name second-part"> {secondPart} </p>
                </div>
                <Topic topic={topic} />
                <ul className="blog-links-list blog-list">
                    <li>
                        <span style={{ color: "white" }}>{content.pages.blog.share}: </span>
                    </li>

                    <LinkedinShareButton url={shareUrl}>
                        <li>
                            <img
                                onClick={() => navigate("#")}
                                src={require("../../assets/icons/white-linkedin.png")}
                                alt="linkedin icon"
                            />
                        </li>
                    </LinkedinShareButton>
                    <FacebookShareButton url={shareUrl}>
                        <li>
                            <img
                                onClick={() => navigate("#")}
                                src={require("../../assets/icons/white-facebook.png")}
                                alt="linkedin icon"
                            />
                        </li>
                    </FacebookShareButton>
                </ul>
            </div>
            <hr />
        </>
    );
});

export default BlogHero;
