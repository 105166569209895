import React from 'react';
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import LanguageContextProvider from "../../contexts/LanguageContext";

const LayOut = ({ children }) => {
    return (
        <LanguageContextProvider>
            <Header />
            <div className='main'>
                {children}
            </div>
            <Footer />
        </LanguageContextProvider>
    );
};

export default LayOut;