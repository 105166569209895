
import { useState, useEffect } from 'react';
import { apiBaseURL } from './../constants/index';
import axios from 'axios';

const useFetchProject = ({ slug, lang }) => {
    const [project, setProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchProject = async () => {
        const url = apiBaseURL + `/portfolios/${slug}?lang=${lang}`;
        try {
            const response = await axios.get(url);
            const data = response.data.data;

            // get images and texts from data
            const { first_image, second_image, third_image, fourth_image, fifth_image, sixth_image, ...withoutImages } = data;
            const { first_text, second_text, third_text, fourth_text, fifth_text, sixth_text, ...withoutTexts } = withoutImages;
            const { meta_keywords, meta_description, seo_title, ...rest } = withoutTexts;

            // put images and texts into array in order to make easier iterations
            const images = [first_image, second_image, third_image, fourth_image, fifth_image, sixth_image];
            const texts = [first_text, second_text, third_text, fourth_text, fifth_text, sixth_text];
            const meta = { meta_keywords, meta_description, meta_title: seo_title }

            setProject({ ...rest, images, texts, meta });
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchProject();
    }, [lang])

    return { isLoading, project, error };
}

export default useFetchProject;