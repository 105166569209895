import React from 'react';
import './Privacy-Policy.scss';
import PrivacyBoard from "./PrivacyBoard";
import { LanguageContext } from "../../contexts/LanguageContext";
import useLanguage from './../../hooks/useLanguage';
import Meta from '../../components/Meta';

const PrivacyPolicy = () => {
    useLanguage({});
    const { content } = React.useContext(LanguageContext);
    return (
        <div className='privacy-policy'>
            <Meta slug='privacy-policy' />
            <h1> {content.common.privacy} </h1>
            <div className='privacy-hero-text'>
                <h3> {content.pages.privacy.head} </h3>
                <p> {content.pages.privacy.subhead} </p>
            </div>
            <PrivacyBoard />
        </div>
    );
};

export default PrivacyPolicy;