import React, { memo } from 'react';
import ServicesLink from "./ServicesLink";
import { LanguageContext } from "../../contexts/LanguageContext";

const ServicesNav = memo(({ current, setCurrent }) => {
    const { content } = React.useContext(LanguageContext);
    return (
        <nav className='services-nav'>
            <ul>
                <li> <ServicesLink current={current} num={1} text={content.common.web} /> </li>
                <li> <ServicesLink current={current} num={2} text={content.common.mobile} /> </li>
                <li> <ServicesLink current={current} num={3} text={content.common.ai} /> </li>
                <li> <ServicesLink current={current} num={4} text={content.common.cyber} /> </li>
                <li> <ServicesLink current={current} num={5} text={content.common.digital} /> </li>
            </ul>
        </nav>
    );
});

export default ServicesNav;