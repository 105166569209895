import React from 'react';
import { LanguageContext } from "../../contexts/LanguageContext";
import ServicesNav from './ServicesNav';
import useLanguage from '../../hooks/useLanguage';
import Meta from './../../components/Meta';

const Ai = () => {
    useLanguage({});
    const { content } = React.useContext(LanguageContext);
    return (
        <>
            <ServicesNav current={3} />
            <Meta slug='artificial-intelligence' />
            <div className='artificial-intelligence'>
                <div className='services-container'>
                    <section>
                        <div className='services-element ai-element'>
                            <div>
                                <h1 className='services-main-title'> {content.common.ai} </h1>
                                <p> {content.pages.services.ai.text1}</p>
                            </div>
                            <div className='services-image-box'>
                                <img className='ai-image ai-image-right global-services-image'
                                    src={require('../../assets/images/services/ai-1.png')} alt="an image" />
                            </div>
                        </div>
                        <div className='services-element ai-element'>
                            <div className='services-image-box'>
                                <img className='ai-image ai-image-left global-services-image'
                                    src={require('../../assets/images/services/ai-2.png')} alt="an image" />
                            </div>
                            <p> {content.pages.services.ai.text2} </p>
                        </div>
                    </section>
                    <section className='services-list-box'>
                        <h1> {content.common.why} </h1>
                        <ul className='services-why-list'>
                            {content.pages.services.ai.reasons.map((reason, index) =>
                                <li key={index}> {reason} </li>
                            )}
                        </ul>
                    </section>
                </div>
            </div>
        </>
    );
};

export default Ai;