import React from 'react';
import { useNavigate } from 'react-router';
import { CommonService } from '../../services/common-service';
import { useContext } from 'react';
import { LanguageContext } from './../../contexts/LanguageContext';

const navigateVariants = (lang, num) => {
    const map = {
        1: 'web-development',
        2: 'mobile-development',
        3: 'artificial-intelligence',
        4: 'cyber-security',
        5: 'digital-marketing'
    }

    return `/${lang}/services/${map[num]}`;
}

const ServicesLink = ({ text, num, current }) => {
    const navigate = useNavigate();
    const { value } = useContext(LanguageContext);

    const onClick = () => {
        CommonService.scrollTop();
        const url = navigateVariants(value, num);
        navigate(url);

    }
    const isCurrent = num === current;

    return (
        <div className='services-nav-link-wrapper'>
            <p onClick={onClick} className={`services-nav-link ${isCurrent ? 'services-nav-link-current' : ''}`}> {text} </p>
            <div className='services-nav-line' style={{ display: isCurrent ? 'block' : 'none' }} />
        </div>
    );
};

export default ServicesLink;