import { useState, useEffect } from 'react';
import { MetaService } from './../services/meta-services';

const useMetaData = (slug, lang) => {
    const [meta, setMeta] = useState(null);
    const [isMetaLoading, setIsMetaLoading] = useState(true);

    const fetchMetaData = async () => {
        try {
            const meta = await MetaService.getMeta(slug, lang);
            setMeta(meta);
            setIsMetaLoading(false);
        } catch (error) {
            alert(error);
        }
    }

    useEffect(() => {
        fetchMetaData();
    }, [lang]);

    return { meta, isMetaLoading };
}

export default useMetaData;