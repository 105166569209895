import React from 'react';
import styles from './HomeServices.module.scss';
import { useNavigate } from "react-router";
import { mainBlue } from "../../../constants";
import Button from "../../../components/ui/Button/Button";
import { CommonService } from "../../../services/common-service";
import { LanguageContext } from "../../../contexts/LanguageContext";

const HomeServices = () => {
    const { content, value } = React.useContext(LanguageContext);
    const navigate = useNavigate();

    const onClick = (subRoute) => {
        CommonService.scrollTop();
        navigate(`/${value}/services/${subRoute}`);
    }

    return (
        <div className={styles.services}>
            <div className={styles["services-item"]}>
                <div className={styles.imageBox + ' global-services-image'}>
                    <img
                        className={styles.image1}
                        src={require('../../../assets/images/services-image-1.png')}
                        alt="services-image"
                    />{" "}
                </div>
                <div className={styles.servicesContent}>
                    <div className={styles.text}>
                        <h3 className={styles.title}> {content.common.web} </h3>
                        <p className={styles.head}> {content.pages.home.services.heads.web}{" "} </p>
                        <p className={styles.subhead}> {content.pages.home.services.texts.web} </p>
                    </div>
                    <Button className='tap-to-more-button' color={mainBlue} onClick={() => onClick('web-development')}> {content.pages.home.taptomore} </Button>
                </div>
            </div>
            <div className={styles["services-item"]}>
                <div className={styles.imageBox + ' global-services-image'}>
                    <img
                        className={styles.image2}
                        src={require('../../../assets/images/services-image-2.png')}
                        alt="services-image"
                    />
                </div>
                <div className={styles.servicesContent}>
                    <div className={styles.text}>
                        <h3 className={styles.title}> {content.common.mobile} </h3>
                        <p className={styles.head}> {" "} {content.pages.home.services.heads.mobile} {" "} </p>
                        <p className={styles.subhead}> {content.pages.home.services.texts.mobile} </p>
                    </div>
                    <Button className='tap-to-more-button' color={mainBlue} onClick={() => onClick('mobile-development')}> {content.pages.home.taptomore} </Button>
                </div>
            </div>

            <div className={styles["services-item"]}>
                <div className={styles.imageBox + ' global-services-image'}>
                    <img
                        className={styles.image3}
                        src={require('../../../assets/images/services-image-3.png')}
                        alt="services-image"
                    />{" "}
                </div>
                <div className={styles.servicesContent}>
                    <div className={styles.text}>
                        <h3 className={styles.title}> {content.common.ai} </h3>
                        <p className={styles.head}> {" "} {content.pages.home.services.heads.ai} {" "} </p>
                        <p className={styles.subhead}> {content.pages.home.services.texts.ai} </p>
                    </div>
                    <Button className='tap-to-more-button' color={mainBlue} onClick={() => onClick('artificial-intelligence')}> {content.pages.home.taptomore} </Button>
                </div>
            </div>

            <div className={styles["services-item"]}>
                <div className={styles.imageBox + ' global-services-image'}>
                    <img
                        className={styles.image4}
                        src={require('../../../assets/images/services-image-4.png')}
                        alt="services-image"
                    />{" "}
                </div>
                <div className={styles.servicesContent}>
                    <div className={styles.text}>
                        <h3 className={styles.title}> {content.common.cyber} </h3>
                        <p className={styles.head}> {" "} {content.pages.home.services.heads.cyber} {" "} </p>
                        <p className={styles.subhead}> {content.pages.home.services.texts.cyber} </p>
                    </div>
                    <Button className='tap-to-more-button' color={mainBlue} onClick={() => onClick('cyber-security')}> {content.pages.home.taptomore} </Button>
                </div>
            </div>
        </div >
    );
};

export default HomeServices;