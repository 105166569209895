import axios from "axios";
import { apiBaseURL } from "../constants";

export class BlogsService {
    static async findOne(slug, language) {
        const url = `${apiBaseURL}/blogs/` + slug + `?lang=${language || 'az'}`;
        return await axios.get(url);
    }

    static async findAll(language) {
        const url = `${apiBaseURL}/blogs?lang=${language || 'az'}`;
        return await axios.get(url);
    }

    static async search(value) {
        const url = `${apiBaseURL}/blogs/search?search=`;
        return await axios.get(url + value);
    }

}