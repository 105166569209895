import React from 'react';
import Button from "../../ui/Button/Button";
import { mainGreen } from "../../../constants";
import { ContactService } from "../../../services/contact-service";
import { CommonService } from "../../../services/common-service";
import { LanguageContext } from "../../../contexts/LanguageContext";
import Modal from "../../Modal/Modal/Modal";
import styles from './Form.module.scss';

const Form = ({ className }) => {
    const { content } = React.useContext(LanguageContext);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('+994');
    const [services, setServices] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [modal, setModal] = React.useState(false);

    const reset = () => {
        setName('');
        setEmail('');
        setPhone('+994');
        setServices('');
        setMessage('');
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        const dto = { name, email, phone, services, message }
        try {
            const response = await ContactService.send(dto);
            setModal(true);
            reset();
        } catch (error) {
            alert(CommonService.getErrorMessage(error));
        }
    }

    return (
        <>
            <section className={styles.container + ' ' + className}>
                <form onSubmit={onSubmit} className={styles.form}>
                    <label htmlFor="name"> {content.pages.contact.form.name}* </label>
                    <input
                        type="text"
                        id="name"
                        required={true}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <label htmlFor="email"> {content.pages.contact.form.email}* </label>
                    <input
                        type="email"
                        id="email"
                        required={true}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <label htmlFor="phone"> {content.pages.contact.form.phone} </label>
                    <input
                        type="text"
                        id="phone"
                        required={false}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />

                    <label htmlFor="services"> {content.pages.contact.form.services} </label>
                    <input
                        type="text"
                        id="services"
                        required={false}
                        value={services}
                        onChange={(e) => setServices(e.target.value)}
                    />

                    <label htmlFor="message"> {content.pages.contact.form.message}* </label>
                    <textarea
                        cols={20}
                        rows={3}
                        id="message"
                        required={true}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />

                    <Button color={mainGreen} className={styles.button}> {content.pages.contact.form.button} </Button>
                </form>
            </section>
            <Modal setModal={setModal} modal={modal}>
                <Message onClose={() => setModal(false)}> {content.common.alert} </Message>
            </Modal>
        </>
    );
};

const Message = ({ onClose, children }) => {

    return (
        <div
            style={{
                backgroundColor: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1.5em',
                borderRadius: '15px',
                flexDirection: 'column'
            }}
        >
            <p
                style={{
                    color: 'white',
                    marginBottom: '1em'
                }}
            > {children} </p>
            <Button onClick={onClose} color={mainGreen}> Ok </Button>
        </div>
    )
}

export default Form;