import React from 'react';
import './AboutIntroduciton.scss';
import {LanguageContext} from "../../../contexts/LanguageContext";

const AboutIntroduction = () => {
    const {content} = React.useContext(LanguageContext);
    return (
        <div className='about-introduction'>
                <div>
                    <h1> {content.common.about} </h1>
                    <p className='about-introduction-paragraph'> {content.pages.about.introduction.text1} </p>
                    <p className='about-introduction-second-paragraph'> {content.pages.about.introduction.text2} </p>
                    <p className='about-introduction-paragraph'> {content.pages.about.introduction.text3} </p>
                </div>
                <div className='about-introduction-aside'>
                    <h2 className='about-introduction-headline'> {content.pages.about.introduction.headline} </h2>
                    <ul>
                        <li>
                            <strong> {content.pages.about.introduction.mission.beginning} </strong>
                            {content.pages.about.introduction.mission.rest}
                        </li>
                        <li>
                            <strong> {content.pages.about.introduction.vision.beginning} </strong>
                            {content.pages.about.introduction.vision.rest}
                        </li>
                    </ul>
                </div>
        </div>
    );
};

export default AboutIntroduction;