import React from 'react';
import Form from '../../components/ui/Form/Form';
import './ContactForm.scss';

const ContactForm = () => {
    return (
        <>
            <Form className='contact-form' />
        </>
    );
};

export default ContactForm;