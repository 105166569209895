import React, { useContext, useEffect, useState } from 'react';
import './FAQ.scss';
import { LanguageContext } from "../../contexts/LanguageContext";
import { apiBaseURL } from '../../constants';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import useLanguage from './../../hooks/useLanguage';
import Meta from '../../components/Meta';

const divideArrry = (arr) => {
    const len = arr.length;
    const breakPoint = Math.ceil(len / 2);

    const firstHalf = [];
    const secondHalf = [];

    for (let i = 0; i < len; i++) {
        const item = arr[i];
        if (i + 1 <= breakPoint) {
            firstHalf.push(item);
        } else {
            secondHalf.push(item)
        }
    }

    return { firstHalf, secondHalf }
}

const Faq = () => {
    useLanguage({})
    const { value } = React.useContext(LanguageContext);
    const [isLoading, setIsLoading] = useState(true);
    const [firstHalf, setFirstHalf] = useState([]);
    const [secondHalf, setSecondHalf] = useState([]);

    const fetchFAQs = async () => {
        const url = apiBaseURL + `/faqs?lang=${value}`;
        try {
            const resposne = await axios.get(url);
            const faqs = (resposne.data.data).filter((faq) => faq.question && faq.question !== '');
            const { firstHalf, secondHalf } = divideArrry(faqs);

            setFirstHalf(firstHalf);
            setSecondHalf(secondHalf);
            setIsLoading(false);
        } catch (error) {
            alert(error);
        }
    }

    useEffect(() => {
        fetchFAQs().catch(console.error)
    }, [value]);

    if (isLoading) return <Loader />

    return (
        <div className='faq-container'>
            <Meta slug='faq' />
            <h1> FAQ </h1>
            <section className='faq-main-section'>
                <div className='faq-first-half'>
                    <RenderFaqs faqs={firstHalf} />
                </div>

                <div className='faq-second-half'>
                    <RenderFaqs faqs={secondHalf} />
                </div>
            </section>
        </div>
    );
};

const RenderFaqs = ({ faqs }) => {
    return (
        <>
            {faqs.map((faq) => {
                const isOdd = faq.id % 2 !== 0;
                return (
                    <div className={`faq-element ${isOdd && 'faq-element-reverse'}`} key={faq.id}>
                        <h2 className={`number-${isOdd ? 'right' : 'left'}`}> {faq.order} </h2>
                        <div>
                            <h3 className='faq-element-title'> {faq.question} </h3>
                            <p> {faq.answer} </p>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default Faq;