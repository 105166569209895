import React from 'react';
import Person from "../../../components/Person/Person";
import {LanguageContext} from "../../../contexts/LanguageContext";

const AboutTeam = () => {
    const {content} = React.useContext(LanguageContext);
    return (
        <div className='about-team'>
            <h1 className='about-title'
                style={{
                    marginTop: '0.5em',
                    textAlign: 'center'
                }}> {content.pages.about.ourTeam} </h1>
            <div>
                <Person
                    image={require('../../../assets/images/team/huseynli.png')}
                    name={'Huseynli'}
                    job={content.pages.about.team.huseynli}
                />
                <Person
                    image={require('../../../assets/images/team/atayev.png')}
                    name={'Atayev'}
                    job={content.pages.about.team.atayev}
                />
                <Person
                    image={require('../../../assets/images/team/rahimov.png')}
                    name={'Rahimov '}
                    job={content.pages.about.team.rahimov}
                />
                <Person
                    image={require('../../../assets/images/team/tagizade.png')}
                    name={'Tagi'}
                    job={content.pages.about.team.tagizade}
                />
                <Person
                    image={require('../../../assets/images/team/kerimli.png')}
                    name={'Karimli'}
                    job={content.pages.about.team.karimli}
                />
                <Person
                    image={require('../../../assets/images/team/salimov.png')}
                    name={'Salimov'}
                    job={content.pages.about.team.salimov}
                />
                <Person
                    image={require('../../../assets/images/team/alakbarli.png')}
                    name={'Alakbarli'}
                    job={content.pages.about.team.alakbarli}
                />
                <Person
                    image={require('../../../assets/images/team/atakishiyeva.png')}
                    name={'Atakishiyeva'}
                    job={content.pages.about.team.atakishiyeva}
                />
                <Person
                    image={require('../../../assets/images/team/guliyeva.png')}
                    name={'Guliyeva'}
                    job={content.pages.about.team.guliyeva}
                />
                <Person
                    image={require('../../../assets/images/team/maharramova.png')}
                    name={'Maharramova'}
                    job={content.pages.about.team.maharramova}
                />
                <Person
                    image={require('../../../assets/images/team/aliyeva.png')}
                    name={'Aliyeva'}
                    job={content.pages.about.team.aliyeva}
                />
                <Person
                    image={require('../../../assets/images/team/sayilova.png')}
                    name={'Sayilova'}
                    job={content.pages.about.team.sayilova}
                />
            </div>
        </div>
    );
};


export default AboutTeam;