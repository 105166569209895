import { useState, useEffect } from 'react';
import { apiBaseURL } from './../constants/index';
import axios from 'axios';

const useFetchProjectsCategories = (lang) => {
    const [categories, setCategories] = useState([]);
    const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCategories = async () => {
        const url = apiBaseURL + `/portfolio-categories?lang=${lang}`
        try {
            const reposnse = await axios.get(url);
            setCategories(reposnse.data.data);
        } catch (error) {
            setError(error);
        } finally {
            setIsCategoriesLoading(false);
        }
    }

    useEffect(() => {
        fetchCategories();
    }, [lang]);

    return { categories, isCategoriesLoading, error };
}

export default useFetchProjectsCategories;