import React from 'react';
import './Home.scss';
import HomeDigital from "./HomeDigital/HomeDigital";
import HomeTechs from "./HomeTechs/HomeTechs";
import HomeBlog from "./HomeBlog/HomeBlog";
import HomeIntroduction from "./HomeIntroduction/HomeIntroduciton";
import HomeServices from "./HomeSevices/HomeServices";
import CurvedLine from "../../components/CurvedLine/CurvedLine";
import { mainBlue } from "../../constants";
import HomeEnd from "./HomeEnd/HomeEnd";
import { LanguageContext } from "../../contexts/LanguageContext";
import useLanguage from '../../hooks/useLanguage';
import Meta from './../../components/Meta';

const Home = () => {
    const { content } = React.useContext(LanguageContext)
    useLanguage({});

    return (
        <div className='home'>
            <Meta slug='home-page' />
            <HomeIntroduction />
            <div className='home-services-container'>
                <CurvedLine color={mainBlue} />
                <h1 className='home-services-title'> {content.common.services} </h1>
                <div className='home-services-line' />
                <HomeServices />
            </div>
            <HomeDigital />
            <HomeTechs />
            <HomeBlog />
            <HomeEnd />
        </div>
    );
};

export default Home;