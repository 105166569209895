import { Helmet } from "react-helmet";

const StaticMeta = ({ meta }) => {
    return (
        <Helmet>
            <title> {`${meta.meta_title}`} </title>
            <meta key={1} name='keywords' content={`${meta.meta_keywords}`} />
            <meta key={2} name='description' content={`${meta.meta_description}`} />
        </Helmet>
    );
}

export default StaticMeta;