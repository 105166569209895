import React from 'react';
import './Digital-Marketing.scss';
import SMM from "./SMM";
import SEO from "./SEO";
import SEM from "./SEM";
import ServicesNav from '../ServicesNav';
import useLanguage from '../../../hooks/useLanguage';
import Meta from './../../../components/Meta';

const DigitalMarketing = () => {
    useLanguage({});
    return (
        <>
            <ServicesNav current={5} />
            <Meta slug='digital-marketing' />
            <div className='digital-marketing'>
                <SMM />
                <SEO />
                <SEM />
            </div>
        </>
    );
};

export default DigitalMarketing;