export const aosConfig = {
    'data-aos': 'fade-up',
    'data-aos-duration': '500'
}

const ProjectAdditionalData = ({ description, year, services, country }) => {
    return (
        <>
            <div className="project-additional-data">
                <div {...aosConfig} dangerouslySetInnerHTML={{ __html: description }} />
                <div {...aosConfig} className='project-additional-info'>
                    <ul className="project-additional-data-first-list">
                        <li> Year </li>
                        <li> Services </li>
                        <li> Country </li>
                    </ul>
                    <ul>
                        <li> {year} </li>
                        <li>
                            {services && services.split(',').map((service, index) => <span key={index}> {service} {index < services.length - 1 && ' / '} </span>)}
                        </li>
                        <li> {country} </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default ProjectAdditionalData;