import React from 'react';
import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Contact from "../pages/Contact/Contact";
import FAQ from "../pages/FAQ/FAQ";
import About from "../pages/About/About";
import Blogs from "../pages/Blogs/Blogs";
import PrivacyPolicy from "../pages/Privacy-Policy/PrivacyPolicy";
import Error from "../pages/Error/Error";
import Home from "../pages/Home/Home";
import Blog from "../pages/Blog/Blog";
import LayOut from "../components/layout/LayOut";
import CircleContactBox from '../components/CircleContactbox/CircleContactBox';
import Portfolio from '../pages/Portfolio/Portfolio';
import Project from '../pages/Project/Project';
import Web from '../pages/Services/Web';
import Mobile from '../pages/Services/Mobile';
import AI from '../pages/Services/AI';
import CyberSecurity from '../pages/Services/CyberSecurity';
import DigitalMarketing from '../pages/Services/Digital/DigitalMarketing';
import Aos from 'aos';
import { useEffect } from 'react';
import { languages } from '../contexts/LanguageContext';

const App = () => {
    const _lang = localStorage.getItem('language');
    const currentLanguage = _lang || 'en';

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, []);

    console.log("What are you looking for?, If you are interested Javachip Company please send us your CV to cv@thejavachip.com.")

    return (
        <>
            <BrowserRouter>
                <LayOut>
                    <Routes>
                        {/* Home */}
                        {languages.map((_, index) => <Route key={index} path={'/'} element={<Navigate to={`/${currentLanguage}`} />} />)}
                        {languages.map((lang, index) => <Route key={index} path={`/${lang}`} element={<Home />} />)}
                        {/* Home */}

                        {/* Services */}
                        {languages.map((lang, index) =>
                            <Route key={index} path={`/${lang}/services`} >
                                <Route index element={<Navigate to={`/${lang}/services/web-development`} />} />
                                <Route path="web-development" element={<Web />} />
                                <Route path='mobile-development' element={<Mobile />} />
                                <Route path='artificial-intelligence' element={<AI />} />
                                <Route path='cyber-security' element={<CyberSecurity />} />
                                <Route path='digital-marketing' element={<DigitalMarketing />} />
                            </Route>)
                        }
                        {/* Services */}

                        {/* About */}
                        {languages.map((lang, index) => <Route key={index} path={`/${lang}/about-us`} element={<About />} />)}
                        {/* About */}

                        {/* Contact */}
                        {languages.map((lang, index) => <Route key={index} path={`/${lang}/contact-us`} element={<Contact />} />)}
                        {/* Contact */}

                        {/* Privacy Policy */}
                        {languages.map((lang, index) => <Route key={index} path={`/${lang}/privacy-policy`} element={<PrivacyPolicy />} />)}
                        {/* Privacy Policy */}

                        {/* FAQ */}
                        {languages.map((lang, index) => <Route key={index} path={`/${lang}/faq`} element={<FAQ />} />)}
                        {/* FAQ */}

                        {/* Blogs */}
                        {languages.map((lang, index) => <Route key={index} path={`/${lang}/blogs`} element={<Blogs />} />)}
                        {/* Blogs */}

                        {/* Blog */}
                        {languages.map((lang, index) => <Route key={index} path={`/${lang}/blog/:slug`} element={<Blog />} />)}
                        {/* Blog */}

                        {/* Portfolio */}
                        {languages.map((lang, index) => <Route key={index} path={`/${lang}/portfolio`} element={<Portfolio />} />)}
                        {/* Portfolio */}

                        {/* Project */}
                        {languages.map((lang, index) => <Route key={index} path={`/${lang}/projects/:slug`} element={<Project />} />)}
                        {/* Project */}

                        {/* Error */}
                        <Route path="*" element={<Navigate to='error' />} />
                        <Route path="error" element={<Error />} />
                        {/* Error */}

                    </Routes>
                </LayOut>
            </BrowserRouter >
            <CircleContactBox />
        </>
    );
};

export default App;