import { aosConfig } from "../ProjectAdditionalData/ProjectAdditionalData";
import { storageURL } from './../../../components/BlogLink/BlogLink';

const ProjectSection = ({ order, image, text }) => {

    const themes = {
        1: {
            bg: 'rgb(230,230,230)',
            text: 'black'
        },
        2: {
            bg: 'white',
            text: 'black'
        },
        3: {
            bg: '#181818',
            text: 'white'
        }
    }

    return (
        <>
            {image &&
                <div
                    className="project-section"
                    style={{
                        backgroundColor: themes[order].bg,
                        color: themes[order].text
                    }}
                >
                    <h2 {...aosConfig}> {text} </h2>
                    <img
                        {...aosConfig}
                        src={storageURL + image}
                        alt="project section image"
                        className="project-section-image-box"
                    />
                </div>
            }
        </>
    );
}

export default ProjectSection;