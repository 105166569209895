import React, { useContext } from 'react';
import './Blog.scss';
import BlogHero from "./BlogHero";
import BlogContent from "./BlogContent";
import BlogAside from "./BlogAside";
import RelatedPosts from "./RelatedPosts";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router";
import { BlogsService } from "../../services/blogs-service";
import { CommonService } from "../../services/common-service";
import { LanguageContext } from '../../contexts/LanguageContext';
import useLanguage from './../../hooks/useLanguage';
import Loader from '../../components/Loader/Loader';
import StaticMeta from './../../components/StaticMeta';

const Blog = () => {
    useLanguage({});
    const { slug } = useParams();
    const { value } = useContext(LanguageContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(true);
    const [author, setAuthor] = React.useState(null);
    const [blog, setBlog] = React.useState(null);
    const [meta, setMeta] = React.useState(null);

    const reset = () => {
        setIsLoading(true);
        setAuthor(null);
        setBlog(null);
        setMeta(null);
    }

    const distributeData = (data) => {
        const { author, job_title, meta_keywords, meta_description } = data;
        setAuthor({
            name: author,
            profession: job_title
        });
        setBlog(data);
        setMeta({ meta_keywords, meta_description, meta_title: data.seo_title });
        setIsLoading(false);
    }

    const onDataSet = (data) => {
        distributeData(data);
    }

    const fetchData = async () => {
        try {
            const response = await BlogsService.findOne(slug, value);
            const data = response.data.data;

            if (!data) navigate('/error');
            onDataSet(data);
        } catch (error) {
            if (error.response.status === 404) {
                navigate('/error');
            } else {
                alert(CommonService.getErrorMessage(error));
            }
        }
    }

    React.useEffect(() => {
        reset();
        fetchData().catch(console.error)
    }, [value, slug]);

    if (isLoading) return <Loader />

    return (
        <div className='blog-container'>
            <StaticMeta meta={meta} />
            <BlogHero author={author} topic={blog.title} />
            <div className='blog-main'>
                <BlogContent blog={blog} />
                <BlogAside currentBlogTitle={blog.title} onDataSet={onDataSet} />
            </div>
            <RelatedPosts currentCategoryId={blog.category_id} currentId={blog.id} />
        </div>
    );
};

export default Blog;