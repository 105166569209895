import React from 'react';
import CurvedLine from "../../../components/CurvedLine/CurvedLine";
import Carousel from "react-multi-carousel";
import BlogLink from "../../../components/BlogLink/BlogLink";
import { LanguageContext } from "../../../contexts/LanguageContext";
import { CommonService } from "../../../services/common-service";
import Loader from "../../../components/Loader/Loader";
import useFetchBlogs from "../../../hooks/useFetchBlogs";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 640 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 640, min: 0 },
        items: 1
    }
}

const HomeBlog = () => {
    const { content, value } = React.useContext(LanguageContext);

    const { blogs, isLoading } = useFetchBlogs(value);
    if (isLoading) return <Loader />

    return (
        <div className='home-blog'>
            <div>
                <CurvedLine color='white' />
                <h1 className='home-blog-title'> {content.common.blog} </h1>
            </div>
            <Carousel
                containerClass='multi-carousel-wrapper home-blog-carousel'
                responsive={responsive}
                showDots={false}
                arrows={true}
                swipeable={true}
            >
                {(blogs).map((blog) => {
                    return (
                        <BlogLink slug={blog.slug} key={blog.id} image={blog.image}> {blog.title} </BlogLink>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default HomeBlog;