const ProjectHero = ({ image, title, website }) => {
    return (
        <div className="project-hero" style={{
            backgroundImage: image
        }}>
            <div className="shadow" />
            <h2> {title} </h2>
            <h2 className="project-hero-url"> {website} </h2>
        </div >
    );
}

export default ProjectHero;